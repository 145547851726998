import React, { Component, useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch, useStore } from 'react-redux';
// import store from 'app/store';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import debounce from 'lodash.debounce';
import { setCurrentMenu } from 'features/menu/menuSlice';
import postal from 'postal';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import LockIcon from '@mui/icons-material/Lock';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel, { getStepLabelUtilityClass } from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import SchemaIcon from '@mui/icons-material/Schema';
import HomeIcon from '@mui/icons-material/Home';
import CheckIcon from '@mui/icons-material/Check';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CircularProgress, {
    circularProgressClasses,
  } from '@mui/material/CircularProgress';

// import { AppModule } from "./modules";
import { MessageHooks } from "../../App";
import { UserInfo, netGet, netPost, registerInitHook } from "../network";
import { AvailableWorkflows } from "./workflow-available";
import { CompletedWorkflows } from "./workflow-completed";
import { openPdfDocument, openPdfDocumentCompare }  from "../webviewer";
import { SelectPdfDraftDialog, UploadFileDialog, ViewDraftOutputDialog, AbortConfirmDialog } from "./workflow-select-draft";
import { EvaluateFormDialog } from "./management-workflow-forms";
import { PortfolioTest } from "./workflow-portfolio";
import { ProcessOptionsDialog } from "./management-workflow-dialogs";
import { ProcessLogViewer } from "./process";
import { WorkflowStep } from "./workflow-step";
import useVisibleOnScreen from "components/visible";

var wreload = 0;
const DEBOUNCE_TIME = 120;
const INSTANCE_PATH = '/C/workflow/instance';
const AVAILABLE_PATH = '/C/workflow/available';
const COMPLETED_PATH = '/C/workflow/completed';

var lastCurrentMenu = '';
const menuSubscription = postal.subscribe({
    topic: "app.menu.change",
    callback: (data, envelope) => {
        // console.log('WORKFLOW GOT menu change: ' + JSON.stringify(data) + ',' + JSON.stringify(envelope));
        // updateLastCurrentMenu(data.menu);
        lastCurrentMenu = data.menu;
    }
});

const typeOf = obj => {
    let type = ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1]
    if (type === 'Object') {
        const results = (/^(function|class)\s+(\w+)/).exec(obj.constructor.toString())
        type = (results && results.length > 2) ? results[2] : ''
    }
    return type.toLowerCase()
};

const stringify  = function(value, space) {
    var cache = [];

    var output = JSON.stringify(value, function (key, value) {

        //filters vue.js internal properties
        if(key && key.length>0 && (key.charAt(0)=="$" || key.charAt(0)=="_")) {

            return;
        }

        if (typeof value === 'object' && value !== null) {
            if (cache.indexOf(value) !== -1) {
                // Circular reference found, discard key
                return;
            }
            // Store value in our collection
            cache.push(value);
        }


        return value;
    }, space)

    cache = null; // Enable garbage collection

    return output;
}

function ChooseViewPdfDialog(props) {
    const {
        open,
        files,
        onCancel,
        previousDrafts,
        dcrPortfolio,
        oemPortfolio,
        refPortfolio,
        ...other
    } = props;

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if ( typeof onCancel === 'function' ) {
            onCancel();
        }
    };

    const handleFileClick = (file, label) => {
        /*
        WorkflowModule.pageIndexChange(1);
        const { annotationManager } = pdftronInstance.Core;
        annotationManager.setCurrentUser(UserInfo.info.name);
        pdftronInstance.UI.loadDocument('/file/' + UserInfo.info.sessionId + file);
        */
        openPdfDocument(file, label, previousDrafts, dcrPortfolio, oemPortfolio, refPortfolio);
        onClose();
    }


    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                  minWidth: 500,
                  maxHeight: 600
                }
              }}
        >
            <DialogTitle sx={{fontWeight: 'bold'}}>Choose the PDF File to Open</DialogTitle>
            <DialogContent>
                <List>
                    { files.map( item => (
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => handleFileClick(item.path, item.label)}>
                                <ListItemIcon>
                                    <OpenInNewIcon fontSize="0.92em" fontWeight="bold"/>
                                </ListItemIcon>
                                <ListItemText primary={item.label} />
                            </ListItemButton>
                        </ListItem>
                    )
                    )
                    }
                </List>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onCancelClick} sx={{minWidth: '7em'}}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );

}


function WorkflowStepInfo(props) {
    const {
        def,
        instId,
        stepId,
        history,
        ...other
    } = props;

    const [ stepInstance, setStepInstance] = React.useState(null);

    React.useEffect(() => {

        // console.log('Fetching workflow definition ' + stepId + '...');

        netGet('/api/workflow/step/find/' + instId + '/' + stepId)
            .then(response => response.json())
            .then(data => {
                let si = { ...data };
                if (data.startDate) {
                    // console.log('in date [' + typeOf(data.startDate) + '] = ' + data.startDate);
                    si["startDate"] = new Date(data.startDate);
                }
                if (data.endDate) {
                    si["endDate"] = new Date(data.endDate);
                }
                setStepInstance(si);
                // console.log('Type of startDate: ' + typeOf(si.startDate) + ' = ' + si.startDate);
            })
            .catch(error => {
                // ignore
            });


    }, [instId, stepId]); // add dependency on props to call this for each instance

    return (
        <Box sx={{padding: '2ex'}}>
            { history ?
                <Typography variant="h7" sx={{fontWeight: 'bold'}}>{def.label}</Typography> : null
            }
            <Typography sx={{ paddingTop: '1ex', whiteSpace: 'pre-wrap' }}>{def.description ? def.description : ''}</Typography>
            {stepInstance && stepInstance.user ?
                <Box sx={{ mb: 2, paddingTop: '1ex', marginBottom: 0 }}>
                    <Typography component="span" sx={{ fontWeight: 'bold', display: 'inline-block', minWidth: '8em' }}>{history ? 'Performed by: ' : 'Assigned to: '}</Typography>
                    <Typography component="span">{stepInstance.user}</Typography>
                </Box> : null
            }
            {stepInstance && stepInstance.startDate ?
                <Box sx={{ mb: 2, paddingTop: '0.2ex', marginBottom: 0 }}>
                    <Typography component="span" sx={{ fontWeight: 'bold', display: 'inline-block', minWidth: '8em' }}>Start date: </Typography>
                    <Typography component="span">{stepInstance.startDate.toLocaleString()}</Typography>
                </Box> : null
            }
            {stepInstance && stepInstance.endDate ?
                <Box sx={{ mb: 2, paddingTop: '0.2ex', marginBottom: 0 }}>
                    <Typography component="span" sx={{ fontWeight: 'bold', display: 'inline-block', minWidth: '8em' }}>End date: </Typography>
                    <Typography component="span">{stepInstance.endDate.toLocaleString()}</Typography>
                </Box> : null
            }
        </Box>
    );
}

function SubWorkflowInstance(props) {
    const {
        instance,
        update,
        workflowDef,
        workflowDefList,
        dcrPortfolio,
        oemPortfolio,
        refPortfolio,
        sid,
        stepInstance,
        ...other
    } = props;
    const [activeStep, setActiveStep ] = React.useState(instance ? instance.stepId : -1);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [popoverIndex, setPopoverIndex] = React.useState(-1);
    const [substeps, setSubsteps] = React.useState([]);
    const [parentStep, setParentStep] = React.useState(null);
    const [levelSteps, setLevelSteps] = React.useState([]);


    const desc = '';

    React.useEffect(() => {
        if ( instance ) {
            setActiveStep(instance.stepId);
        }
        if ( workflowDef ) {
            const parentStep = findStep(workflowDef.steps, sid);
            if ( parentStep ) {
                setParentStep(parentStep);
                if ( parentStep.properties && parentStep.properties.substeps ) {
                    let pnum = parentStep.properties.hnum;
                    if (!pnum ) {
                        pnum = `${parentStep.id + 1}`
                    }
                    const lsteps = [];
                    let sindex = 1;
                    parentStep.properties.substeps.forEach( item => {
                        if ( !item.properties ) {
                            item.properties = {};
                        }
                        if ( ! item.sid ) {
                            item.sid = item.id;
                        }
                        if ( ! item.properties.hnum ) {
                            if ( item.name !== 'START' ) {
                                item.properties.hnum = `${pnum}.${sindex}`;
                                sindex++;
                            }
                        }
                        lsteps.push(item.sid);
                    });
                    setLevelSteps(lsteps);
                    setSubsteps(parentStep.properties.substeps);
                }
            }
        }
        /*
        if ( dcrPortfolio ) {
            console.log('SubWorkflow DCR portfolio: ' + JSON.stringify(dcrPortfolio));
        }
        */
        // setDestStepIndex(0);
        // setActionButtonDisabled(false);
    },[instance,update,sid,dcrPortfolio,oemPortfolio,refPortfolio]);

    /*
    React.useEffect(() => {
        //changeGotoStepIndex(0, stepInstance);
        if ( Array.isArray(stepInstance?.properties?.actions)) {
            stepInstance.properties.actions.forEach( item => {
                if ( item.name === 'RUN_DRAFT' || item.name === 'RUN_APPROVAL_DRAFT' || item.name === 'RUN_EDITION_PAUSE') {
                    if ( item.properties ) {
                        const options = { ...item.properties};
                        // setDraftOptions(options);
                    }
                }
            });
        }
    }, [stepInstance]);
    */

    const findStep = (steps,sid) => {
        for(let i=0; i<steps.length; i++) {
            const s = steps[i];
            if ( s.sid === sid ) {
                return s;
            }
            if ( s.properties && s.properties.substeps ) {
                const ss = findStep(s.properties.substeps, sid);
                if ( ss ) {
                    return ss;
                }
            }
        }
        return undefined;
    };

    const findTargetStep = (steps, sid) => {
        let target = undefined;
        for(const item of steps) {
            if ( sid === item.sid) {
                target = item;
                break;
            }
            if ( item.name === 'CALL') {
                if ( item.properties ) {
                    const substeps = item.properties.substeps;
                    if ( substeps ) {
                        target = findTargetStep(substeps,sid);
                        if ( target ) {
                            break;
                        }
                    }
                }
            }
        }
        return target;
    }


    const handlePopoverClick = (event, index) => {
        if ( index < activeStep ) {
            setAnchorEl(event.currentTarget);
            setPopoverIndex(index);
        }
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setPopoverIndex(-1);
    };

    const openPopOver = Boolean(anchorEl);


    const getActiveStep = (astep) => {
        if ( levelSteps.includes(astep) ) {
            return astep;
        }
        const stepDef = findTargetStep(workflowDef.steps, astep);
        if ( stepDef && levelSteps.includes(stepDef.sid) ) {
            return stepDef.sid;
        } else {
            console.log('Step not found: ' + astep);
        }
        if ( stepDef && stepDef.pid ) {
            if (levelSteps.includes(stepDef.pid) ) {
                return stepDef.pid;
            } else {
                return getActiveStep(stepDef.pid);
            }
        }
        return astep; // fallback
    };


    return (
        substeps && substeps.length > 0 ? (
            
            <Box sx={{ maxWidth: '100%', padding: '20px', height: '100%', overflow: 'auto' }}>
                <Stepper activeStep={getActiveStep(activeStep)} orientation="vertical">
                    {
                        substeps.map( (step,index) => 
                            { return step.name != 'START' && step.name != 'END' ?
                            <Step key={'WIS' + step.sid} index={step.sid}>
                                <StepLabel icon={step.properties && step.properties.hnum ? step.properties.hnum : `${index+1}`}>
                                    <Chip label={step.label} variant="outlined" clickable={step.sid < activeStep} onClick={(event) => handlePopoverClick(event, step.sid)} />
                                    <Popover
                                        id={openPopOver && popoverIndex === step.sid ? 'WISPO' + step.sid : undefined}
                                        open={openPopOver && popoverIndex === step.sid}
                                        anchorEl={anchorEl}
                                        onClose={handlePopoverClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <WorkflowStepInfo def={step} instId={instance.id} stepId={step.sid} history={true} />
                                    </Popover>
                                </StepLabel>
                                <StepContent sx={{paddingBottom: 0}}>
                                    <Typography sx={{whiteSpace: 'pre-wrap'}}>{step.description ? step.description : desc }</Typography>
                                    { activeStep === step.sid &&
                                        <WorkflowStep workflowDef={workflowDef} workflowInstance={instance} workflowDefList={workflowDefList}
                                        sid={step.sid} stepInstance={stepInstance} dcrPortfolio={dcrPortfolio} oemPortfolio={oemPortfolio} refPortfolio={refPortfolio} stepDef={step} />
                                    }
                                </StepContent>
                            </Step> : null
                                }
                        )
                    }
                </Stepper>
                
            </Box>
        ) : null
      );
  
}


function WorkflowInstance(props) {
    const {
        instance,
        update,
        ...other
     } = props;
    const [ workflowDef, setWorkflowDef ] = React.useState(null);
    const [ dcrPortfolio, setDcrPortfolio] = React.useState(null); // the source portfolio with the DCR documents
    const [ oemPortfolio, setOemPortfolio] = React.useState(null); // the OEM portfolio
    const [ refPortfolio, setRefPortfolio] = React.useState(null); // the References portfolio
    const [ workflowUpdate, setWorkflowUpdate] = React.useState(0); // increment to update
    const [ activeStep, setActiveStep ] = React.useState(instance ? instance.stepId : -1);
    const [ stepInstance, setStepInstance] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [popoverIndex, setPopoverIndex] = React.useState(-1);
    const [workflowDefList, setWorkflowDefList] = React.useState([]);
    // steps' SIDs at this level, excluding the nested subworkflows steps
    const [levelSteps, setLevelSteps] = React.useState([]);
    // all def steps including substeps
    const [allSteps, setAllSteps] = React.useState([]);
    const [draftOptions, setDraftOptions] = React.useState({});

    const desc = '';

    React.useEffect(() => {
        if (instance) {
            try {
            // console.log('Fetching workflow definition ' + instance.definitionId + '...');

            netGet('/api/workflow/def/' + instance.definitionId)
                .then(response => response.json())
                .then(data => {
                    // console.log(Array.isArray(data.steps) + ' : ' + data.steps.length);

                    setWorkflowDef(data);
                    // console.log('Active step: ' + instance.stepId);
                    
                    setActiveStep(instance.stepId);
                    if ( Array.isArray(data.steps) ) {
                        let lsteps = [];
                        data.steps.forEach( item => {
                            if ( ! item.sid ) {
                                item.sid = item.id;
                            }
                            if ( ! item.properties ) {
                                item.properties = {};
                            }
                            if ( ! item.properties.hnum ) {
                                item.properties.hnum = `${item.id + 1}`;
                            }
                            lsteps.push(item.sid);
                        });
                        setLevelSteps(lsteps);

                        let asteps = [];
                        loadAllSteps(asteps, data.steps);
                        setAllSteps(asteps);
                    }

                    netGet('/api/workflow/step/find/' + instance.id + '/' + instance.stepId)
                        .then(response => response.json())
                        .then(data => {
                            let si = { ...data };
                            if (data.startDate) {
                                // console.log('in date [' + typeOf(data.startDate) + '] = ' + data.startDate);
                                si = { ...data, startDate: new Date(data.startDate) };
                            }
                            if ( data.endDate ) {
                                si = { ...data, endDate: new Date(data.endDate) };
                            }
                            setStepInstance(si);
                            // setDestStepIndex(getGotoStepIndex(si));
                            // console.log('Type of startDate: ' + typeOf(si.startDate) + ' = ' + si.startDate);
                            if ( Array.isArray(si?.properties?.actions)) {
                                si.properties.actions.forEach( item => {
                                    if ( item.name === 'RUN_DRAFT' || item.name === 'RUN_APPROVAL_DRAFT' || item.name === 'RUN_EDITION_PAUSE') {
                                        if ( item.properties ) {
                                            const options = { ...item.properties};
                                            setDraftOptions(options);
                                        }
                                    }
                                });
                            }
                        })
                        .catch( error => {
                            console.log('Error fetching step: ' + error);
                        });

                    // TODO: make sure any form that sets document propagates to instance.properties.document
                    let docid = instance.properties?.document;
                    if ( !docid || docid === '' ) {
                        docid = data.attributes.document;
                    }
                    console.log('Document: ' + docid);
                    netGet('/api/dlib/port?doc=' + docid + '&sts=OPEN&name=DCR')
                        .then(response => response.json())
                        .then(data => {
                            // console.log('DCR portfolio: ' + JSON.stringify(data));
                            
                            setDcrPortfolio(data);
                        }).catch( error => {
                            // console.log('Error fetching dlib DCR portfolio: ' + error);
                            setDcrPortfolio(null);
                        });
                    netGet('/api/dlib/port?doc=' + docid + '&sts=OPEN&name=OEM')
                        .then(response => response.json())
                        .then(data => {
                            // console.log('DLIB portfolio: ' + JSON.stringify(data));
                            
                            setOemPortfolio(data);
                        }).catch( error => {
                            // console.log('Error fetching dlib OEM portfolio: ' + error);
                            setOemPortfolio(null);
                        });
                    netGet('/api/dlib/port?doc=' + docid + '&sts=OPEN&name=REF')
                        .then(response => response.json())
                        .then(data => {
                            // console.log('REF portfolio: ' + JSON.stringify(data));
                            
                            setRefPortfolio(data);
                        }).catch( error => {
                            // console.log('Error fetching dlib References portfolio: ' + error);
                            setRefPortfolio(null);
                        });
                    
                });
            } catch (err) {
                console.log('Error processing instance: ' + err + ' < ' + instance);
            }

            // get also the full list in case we need to resolve jump to other workflows
            loadWorkflowDefinitions();
        } else {
            console.log('Workflow instance undefined!!!');
        }
        // setActionButtonDisabled(false);
    }, [workflowUpdate, instance, update]); // add dependency on props to call this for each instance

    const loadWorkflowDefinitions = () => {
        netGet('/api/workflow/def/list?categ=DEF')
            .then(response => response.json())
            .then(defs => {
                if ( defs && Array.isArray(defs) ) {
                    // console.log('Workflow Definitions: ' + defs.length);
                    setWorkflowDefList(defs);
                }

            }).catch ( error => {
                // console.log('Error fetching workflow definitions: ' + error);
            });
    };

    const loadAllSteps = (stepList, steps) => {
        if (Array.isArray(steps) && Array.isArray(stepList)) {
            steps.forEach(item => {
                stepList.push(item);
                if (item.name === 'CALL') {
                    if (item.properties?.substeps) {
                        loadAllSteps(stepList, item.properties.substeps);
                    }
                }
            });
        }
    };


    const handlePopoverClick = (event, index) => {
        if ( index < activeStep ) {
            setAnchorEl(event.currentTarget);
            setPopoverIndex(index);
        }
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setPopoverIndex(-1);
    };

    const openPopOver = Boolean(anchorEl);


    const findTargetStep = (steps, sid) => {
        let target = undefined;
        for(const item of steps) {
            if ( sid === item.sid) {
                target = item;
                break;
            }
            if ( item.name === 'CALL') {
                if ( item.properties ) {
                    const substeps = item.properties.substeps;
                    if ( substeps ) {
                        target = findTargetStep(substeps, sid);
                        if ( target ) {
                            break;
                        }
                    }
                }
            }
        }
        return target;
    }


    const getActiveStep = (astep) => {
        if ( levelSteps.includes(astep) ) {
            return astep;
        }
        const stepDef = findTargetStep(workflowDef.steps, astep);
        if ( stepDef && levelSteps.includes(stepDef.sid) ) {
            return stepDef.sid;
        } else {
            console.log('Step not found at this level: ' + astep);
        }
        if ( stepDef && stepDef.pid ) {
            if (levelSteps.includes(stepDef.pid) ) {
                return stepDef.pid;
            } else {
                return getActiveStep(stepDef.pid);
            }
        }
        return astep;
    };

    const getInstanceName = () => {
        if ( instance ) {
            if ( typeof instance.properties?.instanceName === 'string' && instance.properties.instanceName.trim().length > 0 ) {
                return instance.properties.instanceName;
            } else if ( instance.properties?.definitionLabel ) {
                return instance.properties.definitionLabel;
            }
            return getDefinitionLabel();
        }
        return '';
    };

    const getInstanceDescription = () => {
        if ( instance ) {
            if ( instance.description ) {
                return instance.description;
            }
        }
        return '';
    };

    const getDefinitionLabel = () => {
        if ( instance ) {
            const iname = ( typeof instance.properties?.instanceName === 'string' && instance.properties.instanceName.trim().length > 0 ) ? instance.properties.instanceName : null;
            if ( instance.properties?.definitionLabel && iname ) {
                return instance.properties.definitionLabel;
            }
            return '';
        }
        return '';
    }

    const getCurrentStep = (astep) => {
        const cid = getActiveStep(astep);
        // console.log('Active step: ' + astep + ' : levelActiveStep = ' + cid);
        return cid;
    };

    return (
        workflowDef && Array.isArray(workflowDef.steps) && workflowDef.steps.length > 0 ? (
            
            <Box sx={{ maxWidth: '100%', padding: '20px', height: '100%', overflow: 'auto' }}>
                <Typography component="div" variant="h5" sx={{paddingBottom: 0}}>{getInstanceName()}</Typography>
                <Typography component="div" variant="h5" sx={{paddingTop: 0, paddingBottom: '1ex', color: '#6D6D6D', fontSize: '95%'}}>{getDefinitionLabel()}</Typography>
                <Typography sx={{paddingBottom: '2ex', whiteSpace: 'pre-wrap'}}>{getInstanceDescription()}</Typography>
                <Stepper activeStep={getCurrentStep(activeStep)} orientation="vertical">
                    {
                        workflowDef.steps.map( (step,index) => (
                            <Step key={'WIS' + step.sid} index={step.sid}>
                                <StepLabel icon={step.properties && step.properties.hnum ? step.properties.hnum : `${index+1}`}>
                                    <Chip label={step.label} variant="outlined" clickable={step.sid < activeStep} onClick={(event) => handlePopoverClick(event, step.sid)} />
                                    <Popover
                                        id={openPopOver && popoverIndex === step.sid ? 'WISPO' + step.id : undefined}
                                        open={openPopOver && popoverIndex === step.sid}
                                        anchorEl={anchorEl}
                                        onClose={handlePopoverClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <WorkflowStepInfo def={step} instId={instance.id} stepId={step.sid} history={true} />
                                    </Popover>
                                </StepLabel>
                                <StepContent>
                                    <Typography sx={{whiteSpace: 'pre-wrap'}}>{step.description ? step.description : desc }</Typography>
                                    { activeStep === step.sid &&
                                        <WorkflowStep workflowDef={workflowDef} workflowInstance={instance} workflowDefList={workflowDefList}
                                        sid={step.sid} stepInstance={stepInstance} dcrPortfolio={dcrPortfolio} oemPortfolio={oemPortfolio} refPortfolio={refPortfolio} stepDef={step} />
                                    }
                                    { step.name === 'CALL' &&
                                        <SubWorkflowInstance workflowDef={workflowDef} instance={instance} workflowDefList={workflowDefList}
                                            sid={step.sid} stepInstance={stepInstance} dcrPortfolio={dcrPortfolio} oemPortfolio={oemPortfolio}  refPortfolio={refPortfolio}/>
                                    }
                                </StepContent>
                            </Step>
                        ))
                    }
                </Stepper>
                
            </Box>
        ) : null
      );

}

function WorkflowList(props) {
    const {
        workflowList,
        selected,
        onClick: itemClickHandler,
        keyPrefix,
        ...other
    } = props;
     
    const handleItemClick = (index) => {
        if ( itemClickHandler ) {
            itemClickHandler(index);
        }
    };

    const lockIconColor = (index) => {
        let item = workflowList[index];
        if ( item.stepUsers ) {
            if ( Array.isArray(item.stepUsers) ) {
                if ( item.stepUsers.includes(UserInfo.info.name) ) {
                    return 'green';
                } else if ( item.stepUsers.length > 0 ) {
                    return '#cc0000';
                }
            }
        }
        return 'transparent';
    };

    const lockIconLabel = (index) => {
        let item = workflowList[index];
        if ( item.stepUsers ) {
            if ( Array.isArray(item.stepUsers) && item.stepUsers.length > 0 ) {
                return 'Assigned to ' + item.stepUsers[0];
            }
        }
        return '';
    };

    const getInstanceName = (wf) => {
        if ( wf ) {
            const label = wf.properties?.instanceName && wf.properties.instanceName.trim().length > 0 ? wf.properties.instanceName : wf.properties?.definitionLabel;
            
            return label;
        }
        return '';
    }

    const getDefinitionLabel = (wf) => {
        const label = wf.properties?.instanceName && wf.properties.instanceName.trim().length > 0 ? wf.properties?.definitionLabel : '';
        return label;
    }


    return (
        workflowList && Array.isArray(workflowList) && workflowList.length > 0 ? (
            <List sx={{padding: '4px', paddingLeft: '40px', paddingTop: 0 }}>
                {
                    workflowList.map( (witem, index) => (
                        <ListItem key={keyPrefix + witem.id} selected={selected === index} sx={{borderRadius: 1, padding: '8px', marginLeft: '8px', width: 'calc(100% - 8px)'}}> 
                            <Card sx={{ minWidth: '10px', padding: 0, width: '100%'}}>
                                <CardActionArea onClick={() => handleItemClick(index)}>
                                    <CardContent sx={{ padding: '8px', position: 'relative' }}>
                                        <Box sx={{float: 'right', color: lockIconColor(index)}}>
                                            <Tooltip title={lockIconLabel(index)}>
                                                <LockIcon fontSize="18px"/>
                                            </Tooltip>
                                        </Box>
                                        <Typography sx={{ fontSize: 10 }} color="text.secondary" gutterBottom>
                                        {getDefinitionLabel(witem)}
                                        </Typography>
                                        <Typography component="div" sx={{ fontSize: 16 }}>
                                            {getInstanceName(witem)}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </ListItem>
                    ))
                }
            </List>
        ) : null
    );
}


function WorkflowMenu(props) {

    const {
        reload,
        ...other
    } = props;

    const [myWorkflowList, setMyWorkflowList] = React.useState([]);
    const [myWorkflowIndex, setMyWorkflowIndex] = React.useState(-1);
    const [activeWorkflowList, setActiveWorkflowList] = React.useState([]);
    const [activeWorkflowIndex, setActiveWorkflowIndex] = React.useState(-1);
    const [workflowUpdate, setWorkflowUpdate ] = React.useState(0);
    const [instanceUpdate, setInstanceUpdate ] = React.useState(0);
    const [menuIcon, setMenuIcon] = React.useState('app');
    const [pendingUpdates, setPendingUpdates] = useState(false);
    const [lastPageIndex, setLastPageIndex] = useState(0);
    // const [lastCurrentMenu, setLastCurrentMenu] = useState('');
    const [menuACL, setMenuACL] = useState({});
    const theme = useTheme();
    const matchesDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    const [isVisible, containerRef] = useVisibleOnScreen();
    // const currentMenu = useSelector((state) => state.menu.currentMenu);
    const pageIndex = useSelector((state) => state.menu.pageIndex);
    
    // const useDispatch = useDispatch();
    // const store = useStore();

    

    /*
    useEffect(() => {
        

        return () => {
            menuSubscription.unsubscribe();
        }

    }, []);
    */

    React.useEffect(() => {
        // console.log('Updating workflow menu...');
        updateInstances();
    }, [workflowUpdate, reload]);

    React.useEffect(() => {
        // console.log('WORKFLOW VISIBLE: ' + isVisible);
        if ( isVisible ) {
            // console.log('Posting CurrentMenu = workflow...');
            // store.dispatch(setCurrentMenu('workflow'));
            postal.publish({
                topic: "app.menu.change",
                data: {
                    menu: 'workflow',
                }
            });
            if ( pendingUpdates ) {
                setPendingUpdates(false);
                loadUpdates();
            }
            const aclm = {};
            netPost('/api/auth/acl/eval/multi', {
                params: [
                  {
                    path: INSTANCE_PATH,
                    action: 'BROWSE',
                    subjects: UserInfo.info.roles,
                  },
                  {
                    path: INSTANCE_PATH,
                    action: 'READ',
                    subjects: UserInfo.info.roles,
                  },
                  {
                    path: INSTANCE_PATH,
                    action: 'WRITE',
                    subjects: UserInfo.info.roles,
                  },
                  {
                    path: AVAILABLE_PATH,
                    action: 'BROWSE',
                    subjects: UserInfo.info.roles,
                  },
                  {
                    path: AVAILABLE_PATH,
                    action: 'READ',
                    subjects: UserInfo.info.roles,
                  },
                  {
                    path: AVAILABLE_PATH,
                    action: 'WRITE',
                    subjects: UserInfo.info.roles,
                  },
                  {
                    path: COMPLETED_PATH,
                    action: 'BROWSE',
                    subjects: UserInfo.info.roles,
                  },
                  {
                    path: COMPLETED_PATH,
                    action: 'READ',
                    subjects: UserInfo.info.roles,
                  },
                  {
                    path: COMPLETED_PATH,
                    action: 'WRITE',
                    subjects: UserInfo.info.roles,
                  },
                ]
              }).then(response => {
                if (!response.ok) {
                  throw new Error('Status: ' + response.status);
                }
                return response.json();
              })
                .then(data => {
                  // console.log(`ACL Result [${AVAILABLE_PATH}] = ` + JSON.stringify(data));
                  if ( Array.isArray(data) ) {
                    
                      aclm[INSTANCE_PATH] = {
                          hide: !data[0].result,
                          disabled: !data[1].result,
                          write: data[2].result,
                      };
                      aclm[AVAILABLE_PATH] = {
                          hide: !data[3].result,
                          disabled: !data[4].result,
                          write: data[5].result,
                      };
                      aclm[COMPLETED_PATH] = {
                          hide: !data[6].result,
                          disabled: !data[7].result,
                          write: data[8].result,
                      };
                    setMenuACL(aclm);
                  }
                  
                }).catch(error => {
                  console.log('Error: ' + error);
                });
        }
    }, [isVisible]);

    /*
    useEffect( () => {
        console.log('currentMenu changed = ' + currentMenu);
        if ( lastCurrentMenu !== currentMenu) {
            setLastCurrentMenu(currentMenu);
        }
    }, [currentMenu]);
    */

    useEffect( () => {
        if ( pageIndex === 0 && lastPageIndex !== 0 && pendingUpdates ) {
            setPendingUpdates(false);
            loadUpdates();
        }
        if ( pageIndex !== lastPageIndex ) {
            setLastPageIndex(pageIndex);
        }
    }, [pageIndex]);

    const updateLastCurrentMenu = (menu) => {
        // console.log('SETTING lastCurrentMenu = ' + menu);
        // setLastCurrentMenu(menu);
        lastCurrentMenu = menu;
    }

     /*
    React.useEffect(() => {
        registerInitHook('WorkflowMenu', () => {
            wreload = wreload + 1;
            const nupt = workflowUpdate + 1;
            console.log('Changing workflowUpdate to ' + nupt);
            setWorkflowUpdate(nupt);
        });
    }, []);
    */

    const updateInstances = () => {
        // console.log('Fetching workflow instances... ' + reload);

        netGet('/api/workflow/inst/find/applicable')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Status: ' + response.status);
                }
                return response.json();
            })
            .then(data => {
                // console.log(Array.isArray(data) + ' : ' + data.length);
                let activeIndex = activeWorkflowIndex;
                let activeId = -1;
                if ( activeWorkflowIndex >=0 ) {
                    activeId = activeWorkflowList[activeWorkflowIndex].id;
                }

                if (data && Array.isArray(data.active)) {
                    data.active.sort((a,b) => {
                        let atitle = a.properties?.instanceName;
                        if ( !atitle ) {
                            atitle = a.description;
                        }
                        if ( !(typeof atitle === 'string') ) {
                            atitle = '';
                        }
                        let btitle = b.properties?.instanceName;
                        if ( !btitle ) {
                            btitle = b.description;
                        }
                        if ( !(typeof btitle === 'string') ) {
                            btitle = '';
                        }
                        return atitle.localeCompare(btitle);
                    });
                    setActiveWorkflowList(data.active);
                    // console.log('Active workflow list size: ' + data.active.length);
                    if (activeWorkflowIndex >= 0) {
                        activeIndex = data.active.findIndex((value, index, array) => {
                            return activeId === value.id;
                        });
                    }
                } else {
                    setActiveWorkflowList([]);
                    activeIndex = -1;
                }
                

                let myIndex = myWorkflowIndex;
                let myId = -1;
                if ( myWorkflowIndex >= 0 ) {
                    myId = myWorkflowList[myWorkflowIndex].id;
                }
                if (data && Array.isArray(data.mytasks)) {
                    data.mytasks.sort((a,b) => {
                        let atitle = a.properties?.instanceName;
                        if ( !atitle ) {
                            atitle = a.description;
                        }
                        if ( !(typeof atitle === 'string') ) {
                            atitle = '';
                        }
                        let btitle = b.properties?.instanceName;
                        if ( !btitle ) {
                            btitle = b.description;
                        }
                        if ( !(typeof btitle === 'string') ) {
                            btitle = '';
                        }
                        return atitle.localeCompare(btitle);
                    });
                    setMyWorkflowList(data.mytasks);
                    if (myWorkflowIndex >= 0) {
                        myIndex = data.mytasks.findIndex((value, index, array) => {
                            return myId === value.id;
                        });
                        if ( myIndex < 0 && Array.isArray(data.active) ) {
                            // selected workflow is no longer in my tasks
                            // but it should be now in active workflows
                            // so try to find it there
                            activeIndex = data.active.findIndex((value, index, array) => {
                                return myId === value.id;
                            });
                        }
                    }
                } else {
                    setMyWorkflowList([]);
                    myIndex = -1;
                }
                // console.log('myIndex=' + myIndex + ', activeIndex=' + activeIndex + ', activeId=' + activeId );
                if ( myIndex < 0 && activeIndex < 0 && activeId >= 0 && Array.isArray(data.mytasks) ) {
                    // selected workflow is no longer in active workflows
                    // but it should be now in my tasks, so try to find it there
                    myIndex = data.mytasks.findIndex( (value, index, array) => {
                        return activeId === value.id;
                    });
                }
                if ( activeIndex !== activeWorkflowIndex ) {
                    setActiveWorkflowIndex(activeIndex);
                }
                if ( myIndex !== myWorkflowIndex ) {
                    setMyWorkflowIndex(myIndex);
                }
                // console.log('instanceUpdate = ' + instanceUpdate);
                setInstanceUpdate(instanceUpdate => instanceUpdate + 1);
                // console.log('instanceUpdate = ' + instanceUpdate);
                let i = instanceUpdate + 1;
                if (myIndex >= 0) {
                    // console.log('Setting myWorkflow...');
                    WorkflowModule.pageChange(<WorkflowInstance instance={data.mytasks[myIndex]} update={i} />);
                } else if (activeIndex >= 0) {
                    // console.log('Setting activeWorkflow...');
                    WorkflowModule.pageChange(<WorkflowInstance instance={data.active[activeIndex]} update={i} />);
                } else {
                    // console.log('Setting no workflow...');
                    WorkflowModule.pageChange(<div />);
                }

            }).catch( error => {
                console.log('Error: ' + error);
            });
    };

    

   

    const loadUpdates = () => {
        setWorkflowUpdate(workflowUpdate => workflowUpdate + 1);
        wreload = wreload + 1;
        updateInstances();
    };
    
    const handleInboxRootAction = (event) => {
        // mySerial = mySerial + 1;
        // setMyWorkflowList(<MyWorkflowList serial={mySerial} />);
        setWorkflowUpdate(workflowUpdate + 1);
        // initWebViewer();
    };

    const handleActiveRootAction = (event) => {
        // activeSerial = activeSerial + 1;
        // setActiveWorkflowList(<ActiveWorkflowList serial={activeSerial} />);
        setWorkflowUpdate(workflowUpdate + 1);
        // initWebViewer();
    };

    const handleAvailableRootAction = (event) => {
        setMyWorkflowIndex(-1);
        setActiveWorkflowIndex(-1);
        WorkflowModule.pageChange( <AvailableWorkflows /> );
    };

    const handleCompletedRootAction = (event) => {
        setMyWorkflowIndex(-1);
        setActiveWorkflowIndex(-1);
        WorkflowModule.pageChange( <CompletedWorkflows /> );
        // console.log("Completed Workflows...");
    };

    const handlePortfolioRootAction = (event) => {
        setMyWorkflowIndex(-1);
        setActiveWorkflowIndex(-1);
        WorkflowModule.pageChange( <PortfolioTest /> );
        // console.log("Portfolios...");
    };

    const handlePdfTronRootAction = (event) => {
        /*
        WorkflowModule.pageIndexChange( 1 );
        const {  annotationManager } = pdftronInstance.Core;
        annotationManager.setCurrentUser(UserInfo.info.name);
        pdftronInstance.UI.loadDocument('/file/' + UserInfo.info.sessionId + '/opt/UCS/output/DRAFT/TG_777_FCOM/210311124107/PDF/TG_777_FCOM_2021-03-05_draft_controlled_full.pdf');
        */
    };

    const handleInboxItemClick = (index) => {
        
        setMyWorkflowIndex(index);
        setActiveWorkflowIndex(-1);
        // console.log('Opening workflow ' + index);
        WorkflowModule.pageChange( <WorkflowInstance instance={myWorkflowList[index]} update={instanceUpdate} /> );
        if ( !matchesDesktop ) {
            WorkflowModule.drawerOpen(false);
            WorkflowModule.menuIconChange('back');
            setMenuIcon('back');
        }
        // initWebViewer();
    };

    const handleActiveItemClick = (index) => {
        setMyWorkflowIndex(-1);
        setActiveWorkflowIndex(index);
        console.log('Opening workflow ' + index);
        WorkflowModule.pageChange( <WorkflowInstance instance={activeWorkflowList[index]} update={instanceUpdate} /> );
        if ( !matchesDesktop ) {
            WorkflowModule.drawerOpen(false);
            WorkflowModule.menuIconChange('back');
            setMenuIcon('back');
        }
        // initWebViewer();
    };

    const workflowInfo = (message) => {
        let wevent = message.data;
        console.log('Received workflow message: ' + wevent.action + ' : ' + wevent.type + ' : ' + wevent.id);
        if (wevent.type === 'WorkflowInstance') {
            /*
            setWorkflowUpdate(workflowUpdate => workflowUpdate + 1);
            wreload = wreload + 1;
            updateInstances();
            */
            // console.log('CURRENT MENU = ' + lastCurrentMenu );
            if ( lastCurrentMenu === 'workflow' && pageIndex === 0 ) {
                // console.log('updading workflow...');
                loadUpdates();
            } else {
                setPendingUpdates(true);
            }
        }
    };

    const handleMenuIconClick = (event) => {
        /*
        if ( menuIcon === 'back' ) {
            WorkflowModule.menuIconChange('app');
            setMenuIcon('app');
        } else {
            WorkflowModule.menuIconChange('back');
            setMenuIcon('back');
        }
        */
    };
    
    
    MessageHooks["workflow"]["WorkflowMenu"] = workflowInfo;
    WorkflowModule.onMenuIconClick = handleMenuIconClick;

    return (
        <Box ref={containerRef}
            sx={{ width: '100%', fontSize: '1.3em', padding: 0, position: "relative" }}
            role="presentation"
        >
            <List  >
                <ListItem key="w-inbox" disablePadding sx={{display: menuACL[INSTANCE_PATH] && menuACL[INSTANCE_PATH].hide ? "none" : "block"}} >
                    <ListItemButton onClick={handleInboxRootAction} disabled={menuACL[INSTANCE_PATH] && menuACL[INSTANCE_PATH].disabled}>
                        <ListItemIcon sx={{ fontSize: 18, paddingRight: 0, minWidth: '40px' }}>
                            <InboxIcon />
                        </ListItemIcon>
                        <ListItemText primary="My Tasks" primaryTypographyProps={{
                            fontSize: 18,
                            fontWeight: 'bold',
                            letterSpacing: 0,
                        }} />
                    </ListItemButton>
                </ListItem>
                <ListItem key="w-inbox-content" disablePadding sx={{display: menuACL[INSTANCE_PATH] && menuACL[INSTANCE_PATH].hide ? "none" : "block"}}>
                    <ListItemText>
                    <WorkflowList workflowList={myWorkflowList} reload={workflowUpdate} keyPrefix="WIM" onClick={handleInboxItemClick} selected={myWorkflowIndex} />
                    </ListItemText>
                </ListItem>
                <ListItem key="w-active" disablePadding sx={{display: menuACL[INSTANCE_PATH] && menuACL[INSTANCE_PATH].hide ? "none" : "block"}} >
                    <ListItemButton onClick={handleActiveRootAction} disabled={menuACL[INSTANCE_PATH] && menuACL[INSTANCE_PATH].disabled} >
                        <ListItemIcon sx={{ fontSize: 18, paddingRight: 0, minWidth: '40px' }}>
                            <DirectionsRunIcon />
                        </ListItemIcon>
                        <ListItemText primary="Active Workflows" primaryTypographyProps={{
                            fontSize: 18,
                            fontWeight: 'bold',
                            letterSpacing: 0,
                        }} />
                    </ListItemButton>
                </ListItem>
                <ListItem key="w-active-content" disablePadding sx={{display: menuACL[INSTANCE_PATH] && menuACL[INSTANCE_PATH].hide ? "none" : "block"}}>
                    <ListItemText>
                        <WorkflowList workflowList={activeWorkflowList} reload={workflowUpdate} keyPrefix="WIA" onClick={handleActiveItemClick} selected={activeWorkflowIndex} />
                    </ListItemText>
                </ListItem>
                <ListItem key="w-available" disablePadding sx={{display: menuACL[AVAILABLE_PATH] && menuACL[AVAILABLE_PATH].hide ? "none" : "block"}} >
                    <ListItemButton onClick={handleAvailableRootAction} disabled={menuACL[AVAILABLE_PATH] && menuACL[AVAILABLE_PATH].disabled} >
                        <ListItemIcon sx={{ fontSize: 18, paddingRight: 0, minWidth: '40px' }}>
                            <CheckIcon />
                        </ListItemIcon>
                        <ListItemText primary="Available Workflows" primaryTypographyProps={{
                            fontSize: 18,
                            fontWeight: 'bold',
                            letterSpacing: 0,
                        }} />
                    </ListItemButton>
                </ListItem>
                <ListItem key="w-completed" disablePadding sx={{display: menuACL[COMPLETED_PATH] && menuACL[COMPLETED_PATH].hide ? "none" : "block"}} >
                    <ListItemButton onClick={handleCompletedRootAction} disabled={menuACL[COMPLETED_PATH] && menuACL[COMPLETED_PATH].disabled} >
                        <ListItemIcon sx={{ fontSize: 18, paddingRight: 0, minWidth: '40px' }}>
                            <FactCheckIcon />
                        </ListItemIcon>
                        <ListItemText primary="Completed Workflows" primaryTypographyProps={{
                            fontSize: 18,
                            fontWeight: 'bold',
                            letterSpacing: 0,
                        }} />
                    </ListItemButton>
                </ListItem>
                {/* 
                <ListItem key="w-portfolio" disablePadding >
                    <ListItemButton onClick={handlePortfolioRootAction} >
                        <ListItemIcon sx={{ fontSize: 18, paddingRight: 0, minWidth: '40px' }}>
                            <PictureAsPdfIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Portforlios" primaryTypographyProps={{
                            fontSize: 18,
                            fontWeight: 'bold',
                            letterSpacing: 0,
                        }} />
                    </ListItemButton>
                </ListItem>
                */}
            </List>

        </Box>
    );
}

/*
const workflowInfo  = (message) => {
    let wevent = message.data;
    console.log('Received workflow message: ' + wevent.action + ' : ' + wevent.type + ' : ' + wevent.id );
    wreload = wreload + 1;
    console.log('Reload = ' + wreload );
    WorkflowModule.onDrawerChange( <WorkflowMenu reload={wreload}/> );
  };
*/

// –

const WorkflowModule = {
    // label
    name: 'workflow',
    label: 'Workflow–Classic',
    barLabel: 'Workflow',
    barShortLabel: 'UCS',
    // icon
    icon: <SchemaIcon fontSize="inherit" />,
    // menu
    drawerContent: <WorkflowMenu reload={wreload}/>,
    // initial body page
    pageContent: null,
    // send new body page on menu actions
    pageChange: (page) => {},
    pageIndexChange: (index) => {},
    drawerChange: (drawer) => {},
    drawerOpen: (open) => {},
    menuIconChange: (icon) => {},
    onMenuIconClick: (event) => {},
    drawerWidth: 260,

    hooks: {}, // { workflow: { top: workflowInfo } },
    route: 
        {
            path: 'workflow',
            element: <WorkflowMenu reload={wreload}/>,
        }
    ,
    aclPaths: [ 
        {path: '/C/workflow', label: 'Workflow'}, 
        {path: '/C/workflow/instance', label: 'Instances'}, 
        {path: '/C/workflow/available', label: 'Available'},
        {path: '/C/workflow/completed', label: 'Completed'}
    ],
}


export {WorkflowModule as default, WorkflowInstance };

