import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid';

export const form = createSlice({
    name: 'form',
    initialState: {
        action: {open: false, commit: false, form: {}, step: {}},
        disable: {value: false, form: {}},
    },
    reducers: {
        setFormAction: (state, action) => {
            state.action = action.payload;
        },
        setFormDisable: (state,action) => {
            state.action = action.payload;
        },
    },
});

export const { setFormAction, setFormDisable } = form.actions;