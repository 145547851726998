import React, { Component } from "react";
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid } from '@mui/x-data-grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ForwardIcon from '@mui/icons-material/Forward';
import Tooltip from '@mui/material/Tooltip';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import LinearProgress from '@mui/material/LinearProgress';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// import { MuiFileInput } from 'mui-file-input';
// import FileUpload from 'react-material-file-upload';
import { default as FileUpload, netUpload} from '../file-upload';

import { DateTime } from "luxon";


import { UserInfo, netGet, netPost, } from "../network";
import { pdftronInstance, initWebViewer } from "../webviewer";
import NotificationsNone from "@mui/icons-material/NotificationsNone";

const DATE_FORMAT = 'dd MMM yyyy, HH:mm ZZZZ';
const SHELF_FORMAT = 'yyMMdd_HHmmss';

var ABORTED = false;

function SourceRow(props) {
    const { row, onSelectionChange, ...other } = props;
    const [open, setOpen] = React.useState(false);
    const [selected, setSelected] = React.useState([]);
    const lref = React.createRef();

    const theme = useTheme();

    const doSingleClick = (sel, index) => {
        if ( sel.length === 1 && sel.includes(index) ) {
            const i = sel.indexOf(index);
            sel.splice(i,1);
            setSelected(sel);
        } else {
            sel.splice(0, sel.length);
            sel.push(index);
            setSelected(sel);
        }
    };

    const handleItemClick = (event, index) => {
        // handle Ctrl and Shift clicks for multiple selections
        // console.log('ctrl: ' + event.ctrlKey + ' shift: ' + event.shiftKey);
        // MacOS cmd key reports as metaKey on modern browsers
        const sel = [ ...selected];
        if ( event.ctrlKey || event.metaKey) {
            if ( sel.includes(index) ) {
                const i = sel.indexOf(index);
                sel.splice(i,1);
            } else {
                sel.push(index);
            }
            setSelected(sel);
        } else if ( event.shiftKey ) {
            if ( sel.length > 0 ) {
                // add all items from last index
                const last = sel[sel.length -1];
                if ( index === last ) {
                    doSingleClick(sel, index);
                } else if ( index > last ) {
                    for(let i=last+1; i<=index; i++) {
                        sel.push(i);
                    }
                    setSelected(sel);
                } else {
                    for(let i=last-1; i>=index; i--) {
                        sel.push(i);
                    }
                    setSelected(sel);
                }
            } else {
                doSingleClick(sel, index);
            }
        } else {
            doSingleClick(sel, index);
        }
        if ( typeof onSelectionChange === 'function' ) {
            //console.log('selection changed = ' + sel.length);
            onSelectionChange(sel.map( index => row.files[index]));
        }
    };

    const expandHandler = () => {
        setOpen(!open);
        if ( !open ) {
            // console.log('Files expanded...');
            lref.current?.scrollIntoView(true);
        } else {
            // console.log('Files collapsed...');
        }
    };

    return (
        <React.Fragment>
            <TableRow ref={lref} sx={{ '& > *': { borderBottom: 'unset', padding: '0.3ex',
                [theme.breakpoints.up('lg')]: {borderBottom: 'unset', padding: '0.3ex',},
                [theme.breakpoints.down('lg')]: {borderBottom: 'unset', padding: '0.3ex',} } }}>
                <TableCell sx={{ borderBottom: 'unset',paddingBottom: 0, paddingTop: 0, 
                    [theme.breakpoints.up('lg')]: {borderBottom: 'unset',paddingBottom: 0, paddingTop: 0 }, 
                    [theme.breakpoints.down('lg')]: {borderBottom: 'unset',paddingBottom: 0, paddingTop: 0 } }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={expandHandler}
                    >
                        {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" sx={{ borderBottom: 'unset',paddingBottom: 0, paddingTop: 0, 
                    [theme.breakpoints.up('lg')]: {borderBottom: 'unset',paddingBottom: 0, paddingTop: 0 }, 
                    [theme.breakpoints.down('lg')]: {borderBottom: 'unset',paddingBottom: 0, paddingTop: 0 } }}>
                    {row.docName}
                </TableCell>
                <TableCell sx={{ borderBottom: 'unset',paddingBottom: 0, paddingTop: 0, 
                    [theme.breakpoints.up('lg')]: {borderBottom: 'unset',paddingBottom: 0, paddingTop: 0 }, 
                    [theme.breakpoints.down('lg')]: {borderBottom: 'unset',paddingBottom: 0, paddingTop: 0 } }}>{row.startDate}</TableCell>
                <TableCell sx={{ borderBottom: 'unset',paddingBottom: 0, paddingTop: 0, 
                    [theme.breakpoints.up('lg')]: {borderBottom: 'unset',paddingBottom: 0, paddingTop: 0 }, 
                    [theme.breakpoints.down('lg')]: {borderBottom: 'unset',paddingBottom: 0, paddingTop: 0 } }}>{row.filter}</TableCell>
            </TableRow>
            <TableRow  sx={{ '& > *': { padding: '0.3ex' },
                [theme.breakpoints.up('lg')]: {padding: '0.3ex',},
                [theme.breakpoints.down('lg')]: {padding: '0.3ex',} }}>
                <TableCell sx={{ paddingBottom: 0, paddingTop: 0, 
                    [theme.breakpoints.up('lg')]: {paddingBottom: 0, paddingTop: 0 }, 
                    [theme.breakpoints.down('lg')]: {paddingBottom: 0, paddingTop: 0 } }} colSpan={6} size="small">
                    <Collapse in={open} timeout="auto" unmountOnExit >
                        <Box sx={{ margin: 1 }}>
                            <Typography sx={{ fontWeight: 'bold', paddingBottom: 0 }}>
                                Files
                            </Typography>
                            <List sx={{paddingTop: 0}}  >
                                    {row.files.map((file,index) => (
                                        <ListItem key={file.path} dense>
                                            <ListItemButton
                                                selected={selected.includes(index)}
                                                onClick={(event) => handleItemClick(event,index)}
                                            >
                                                <ListItemText primary={file.path.slice(file.path.lastIndexOf('/') + 1)} 
                                                    sx={{padding: 0, margin: 0,
                                                        [theme.breakpoints.up('lg')]: {padding: 0, margin: 0,},
                                                        [theme.breakpoints.down('lg')]: {padding: 0, margin: 0,} }} />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                            </List>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

function SourceWorkflowRow(props) {
    // row is instance of workflowInstance
    const { row, onSelectionChange, ...other } = props;
    const [open, setOpen] = React.useState(false);
    const [selected, setSelected] = React.useState([]);
    const lref = React.createRef();

    const theme = useTheme();

    const doSingleClick = (sel, index) => {
        if ( sel.length === 1 && sel.includes(index) ) {
            const i = sel.indexOf(index);
            sel.splice(i,1);
            setSelected(sel);
        } else {
            sel.splice(0, sel.length);
            sel.push(index);
            setSelected(sel);
        }
    };

    const handleItemClick = (event, index) => {
        // handle Ctrl and Shift clicks for multiple selections
        // console.log('ctrl: ' + event.ctrlKey + ' shift: ' + event.shiftKey);
        // MacOS cmd key reports as metaKey on modern browsers
        const sel = [ ...selected];
        if ( event.ctrlKey || event.metaKey) {
            if ( sel.includes(index) ) {
                const i = sel.indexOf(index);
                sel.splice(i,1);
            } else {
                sel.push(index);
            }
            setSelected(sel);
        } else if ( event.shiftKey ) {
            if ( sel.length > 0 ) {
                // add all items from last index
                const last = sel[sel.length -1];
                if ( index === last ) {
                    doSingleClick(sel, index);
                } else if ( index > last ) {
                    for(let i=last+1; i<=index; i++) {
                        sel.push(i);
                    }
                    setSelected(sel);
                } else {
                    for(let i=last-1; i>=index; i--) {
                        sel.push(i);
                    }
                    setSelected(sel);
                }
            } else {
                doSingleClick(sel, index);
            }
        } else {
            doSingleClick(sel, index);
        }
        if ( typeof onSelectionChange === 'function' && index >= 0) {
            //console.log('selection changed = ' + sel.length);
            onSelectionChange(sel.map( index => row.properties.pdfDraftFile[index]));
        }
    };

    const expandHandler = () => {
        setOpen(!open);
        if ( !open ) {
            // console.log('Files expanded...');
            lref.current?.scrollIntoView(true);
        } else {
            // console.log('Files collapsed...');
        }
    };

    return (
        <React.Fragment>
            <TableRow ref={lref} sx={{ '& > *': { borderBottom: 'unset', padding: '0.3ex',
                [theme.breakpoints.up('lg')]: {borderBottom: 'unset', padding: '0.3ex',},
                [theme.breakpoints.down('lg')]: {borderBottom: 'unset', padding: '0.3ex',} } }}>
                <TableCell sx={{ borderBottom: 'unset',paddingBottom: 0, paddingTop: 0, verticalAlign: 'top',
                    [theme.breakpoints.up('lg')]: {borderBottom: 'unset',paddingBottom: 0, paddingTop: 0 }, 
                    [theme.breakpoints.down('lg')]: {borderBottom: 'unset',paddingBottom: 0, paddingTop: 0 } }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={expandHandler}
                    >
                        {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" sx={{ borderBottom: 'unset',paddingBottom: 0, paddingTop: 0, 
                    [theme.breakpoints.up('lg')]: {borderBottom: 'unset',paddingBottom: 0, paddingTop: 0 }, 
                    [theme.breakpoints.down('lg')]: {borderBottom: 'unset',paddingBottom: 0, paddingTop: 0 } }}>
                    <Typography sx={{fontWeight: 'bold'}}>{row.label}</Typography>
                    <Typography>{row.description}</Typography>
                </TableCell>
                <TableCell sx={{ borderBottom: 'unset',paddingBottom: 0, paddingTop: 0, 
                    [theme.breakpoints.up('lg')]: {borderBottom: 'unset',paddingBottom: 0, paddingTop: 0 }, 
                    [theme.breakpoints.down('lg')]: {borderBottom: 'unset',paddingBottom: 0, paddingTop: 0 } }}>{row.startDate}</TableCell>
                <TableCell sx={{ borderBottom: 'unset',paddingBottom: 0, paddingTop: 0, 
                    [theme.breakpoints.up('lg')]: {borderBottom: 'unset',paddingBottom: 0, paddingTop: 0 }, 
                    [theme.breakpoints.down('lg')]: {borderBottom: 'unset',paddingBottom: 0, paddingTop: 0 } }}>{row.version}</TableCell>
            </TableRow>
            <TableRow  sx={{ '& > *': { padding: '0.3ex' },
                [theme.breakpoints.up('lg')]: {padding: '0.3ex',},
                [theme.breakpoints.down('lg')]: {padding: '0.3ex',} }}>
                <TableCell sx={{ paddingBottom: 0, paddingTop: 0, 
                    [theme.breakpoints.up('lg')]: {paddingBottom: 0, paddingTop: 0 }, 
                    [theme.breakpoints.down('lg')]: {paddingBottom: 0, paddingTop: 0 } }} colSpan={6} size="small">
                    <Collapse in={open} timeout="auto" unmountOnExit >
                        <Box sx={{ margin: 1 }}>
                            <Typography sx={{ fontWeight: 'bold', paddingBottom: 0 }}>
                                Files
                            </Typography>
                            <List sx={{paddingTop: 0}}  >
                                    {row.properties && row.properties.pdfDraftFile && row.properties.pdfDraftFile.map((file,index) => (
                                        <ListItem key={file.path} dense>
                                            <ListItemButton
                                                selected={selected.includes(index)}
                                                onClick={(event) => handleItemClick(event,index)}
                                            > <ListItemText primary={file.label ? file.label : file.path.slice(file.path.lastIndexOf('/') + 1)} 
                                                    sx={{padding: 0, margin: 0,
                                                        [theme.breakpoints.up('lg')]: {padding: 0, margin: 0,},
                                                        [theme.breakpoints.down('lg')]: {padding: 0, margin: 0,} }} />
                                                    
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                            </List>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

function TargetRow(props) {
    const { file, onLabelChange, onItemClick, itemSelected, ...other } = props;
    const [selected, setSelected] = React.useState(false);
    const [label, setLabel] = React.useState('');
    const theme = useTheme();

    React.useEffect( () => {
        setLabel(file.label);
    }, [file]);

    React.useEffect( () => {
        setSelected(itemSelected);
    }, [itemSelected]);
    
    const handleItemClick = (event) => {
        if ( typeof onItemClick === 'function' ) {
            onItemClick(event);
        }
    };

    const handleLabelChange = (event) => {
        setLabel(event.target.value);
        file.label = event.target.value;
        if ( typeof onLabelChange === 'function') {
            onLabelChange(event.target.value);
        }
    };

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset', padding: 0, 
                [theme.breakpoints.up('lg')]: { borderBottom: 'unset', padding: 0 },
                [theme.breakpoints.down('lg')]: {  borderBottom: 'unset', padding: 0} } }}>
                <TableCell padding="none" size="small" sx={{ borderBottom: 'unset',padding: 0, 
                    [theme.breakpoints.up('lg')]: { borderBottom: 'unset', padding: 0 },
                    [theme.breakpoints.down('lg')]: {  borderBottom: 'unset', padding: 0 } }}>
                    <List sx={{padding: '0.5ex'}} >
                        <ListItem dense>
                            <ListItemButton onClick={handleItemClick} selected={selected} >
                                <ListItemText primary={file.path.slice(file.path.lastIndexOf('/') + 1)} sx={{ padding: 0, margin: 0,
                                    [theme.breakpoints.up('lg')]: { padding: 0, margin: 0},
                                    [theme.breakpoints.down('lg')]: { padding: 0, margin: 0}}}/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </TableCell>
                <TableCell sx={{width: '50%', borderBottom: 'unset',padding: 0, 
                    [theme.breakpoints.up('lg')]: { borderBottom: 'unset', padding: 0 },
                    [theme.breakpoints.down('lg')]: {  borderBottom: 'unset', padding: 0 } }}><TextField value={label} onChange={handleLabelChange} size="small" variant="standard"  sx={{width: '100%'}} /></TableCell>
            </TableRow>
        </React.Fragment>
    );
}

/*
Row.propTypes = {
    row: PropTypes.shape({
        docName: PropTypes.string.isRequired,
        startDate: PropTypes.string.isRequired,
        filter: PropTypes.string.isRequired,
        history: PropTypes.arrayOf(
            PropTypes.shape({
                path: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
            }),
        ).isRequired,
    }).isRequired,
};
*/

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0, pt: 3, pb: 1}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function ErrorDialog(props) {
    const {
        title,
        message,
        open,
        onClose,
        ...other
    } = props;

    const onOKClick = () => {
        if ( typeof onClose === 'function' ) {
            onClose();
        }
    };

    const onLocalClose = () => {
        if ( typeof onClose === 'function' ) {
            onClose();
        }
    };

    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onLocalClose}
            PaperProps={{
                sx: {
                  minWidth: 500,
                  maxHeight: 600
                }
              }}
        >
            <DialogTitle sx={{fontWeight: 'bold', backgroundColor: '#d32f2f'}}>{title}</DialogTitle>
            <DialogContent>
                
                <Box sx={{paddingTop: '2ex'}}>
                    <Typography>
                        {message}
                    </Typography>
                </Box>
                
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onOKClick} sx={{minWidth: '7em'}} >Close</Button>
            </DialogActions>
        </Dialog>

    );

}
  

function SelectPdfDraftDialog(props) {
    const {
        open,
        onCancel,
        onSave,
        workflowInstance,
        stepInstance,
        ...other
    } = props;
    const [drafts, setDrafts] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [target, setTarget] = React.useState([]);
    const [selectedTarget, setSelectedTarget] = React.useState([]);
    const [tabIndex, setTabIndex] = React.useState(0);
    const [prevInstances, setPrevInstances] = React.useState([]);
    const [files, setFiles] = React.useState([]);
    const [progressValue, setProgressValue] = React.useState([]);
    const [progressFactor, setProgressFactor] = React.useState(1);
    const [progressShow, setProgressShow] = React.useState([]);
    const fileUploadProgress = useSelector((state) => state.fileUpload.progress);
    const [errorOpen, setErrorOpen] = React.useState(false);
    const [errorTitle, setErrorTitle] = React.useState('Upload Error');
    const [errorMessage, setErrorMessage] = React.useState('An error occurred uploading files. Please try again or consult administrator if problem persists.');

    const theme = useTheme();

    React.useEffect(() => {
        netPost('/api/workflow/step/inst/info', stepInstance)
            .then(response => response.json())
            .then(info => {
                if (info && Array.isArray(info.draftOutputs)) {
                    // console.log('PDF Drafts ' + info.draftOutputs.length);
                    setDrafts(info.draftOutputs.map((item) => {
                        let nitem = { ...item };
                        if (item.startDate) {
                            const sd = DateTime.fromISO(item.startDate);
                            nitem['startDate'] = sd.toFormat(DATE_FORMAT);
                            // console.log(item.startDate + ':' + sd.zoneName + ':' + sd.toFormat('fff'));
                        }
                        if (item.endDate) {
                            const ed = DateTime.fromISO(item.endDate);
                            
                            nitem['endDate'] = ed.toFormat(DATE_FORMAT);
                        }
                        return nitem;
                    }));
                    setSelected([]);
                    if (stepInstance.properties.pdfDraftFile) {
                        setTarget(stepInstance.properties.pdfDraftFile);
                    } else {
                        setTarget([]);
                    }
                    setSelectedTarget([]);
                }
            }).catch(error => console.log('Error: ' + error));
        setFiles([]);
        setErrorOpen(false);
    }, [workflowInstance, stepInstance, open]);

    React.useEffect( () => {
        netGet('/api/workflow/inst/find/completed')
            .then( response => response.json())
            .then( (list) =>{
                if ( Array.isArray(list) ) {
                    setPrevInstances(list.map(item => {
                        let nitem = { ...item};
                        if (item.startDate) {
                            const sd = DateTime.fromISO(item.startDate);
                            nitem['startDate'] = sd.toFormat(DATE_FORMAT);
                        }
                        if (item.endDate) {
                            const ed = DateTime.fromISO(item.endDate);
                            nitem['endDate'] = ed.toFormat(DATE_FORMAT);
                        }
                        return nitem;
                    }));
                }
            }).catch(error => console.log('Error: ' + error));
        setProgressShow(false);
        setProgressFactor(1);
        setProgressValue(0);
    }, [workflowInstance, open]);

    React.useEffect( ()=> {
        setProgressValue(fileUploadProgress / progressFactor);
    }, [fileUploadProgress, progressFactor]);

    const doSingleClick = (sel, index) => {
        if ( sel.length === 1 && sel.includes(index) ) {
            const i = sel.indexOf(index);
            sel.splice(i,1);
            setSelectedTarget(sel);
        } else {
            sel.splice(0, sel.length);
            sel.push(index);
            setSelectedTarget(sel);
        }
    };

    const handleItemClick = (event, index) => {
        // handle Ctrl and Shift clicks for multiple selections
        // console.log('ctrl: ' + event.ctrlKey + ' shift: ' + event.shiftKey);
        const sel = [ ...selectedTarget];
        if ( event.ctrlKey || event.metaKey) {   // MacOS cmd key maps to metaKey on modern browsers
            if ( sel.includes(index) ) {
                const i = sel.indexOf(index);
                sel.splice(i,1);
            } else {
                sel.push(index);
            }
            setSelectedTarget(sel);
        } else if ( event.shiftKey ) {
            if ( sel.length > 0 ) {
                // add all items from last index
                const last = sel[sel.length -1];
                if ( index === last ) {
                    doSingleClick(sel, index);
                } else if ( index > last ) {
                    for(let i=last+1; i<=index; i++) {
                        sel.push(i);
                    }
                    setSelectedTarget(sel);
                } else {
                    for(let i=last-1; i>=index; i--) {
                        sel.push(i);
                    }
                    setSelectedTarget(sel);
                }
            } else {
                doSingleClick(sel, index);
            }
        } else {
            doSingleClick(sel, index);
        }
    };

    const uploadFiles = () => {
        const allFiles = [];
        const localFiles = [];
        

        target.forEach( item => {
            if ( item.origin === 'F' ) {
                localFiles.push(item);
                // netUpload(item.file, `/api/upload/${item.uid}?dst=${item.path}&uid=${item.uid}`);
            } else {
                allFiles.push(item);
            }

        });
        console.log('Local files: ' + localFiles.length);
        setProgressShow(true);
        if ( localFiles.length > 0 ) {
            setProgressFactor(localFiles.length);
        } else {
            setProgressFactor(1);
        }
        setProgressValue(0);
        localFiles.reduce(function(prev, item) {
            return  prev.then( (value) => {
                if ( value.status >= 400 ) {
                    return Promise.reject(value.statusText);
                } else {
                    if ( value.token ) {
                        const dest = {
                            path: value.token.path,
                            label: value.token.label,
                            origin: 'F',
                        };
                        allFiles.push(dest);
                        console.log('Adding ' + JSON.stringify(dest));
                    }
                    return netUpload(item.file, `/api/upload/${item.uid}?dst=${item.path}&uid=${item.uid}`, item);
                }
                });
          }, Promise.resolve({})).then( (value) => {
            if ( value.status >= 400 ) {
                return Promise.reject(value.statusText);
            } else if ( value.token ) {
                const dest = {
                    path: value.token.path,
                    label: value.token.label,
                    origin: 'F',
                };
                allFiles.push(dest);
                setProgressShow(false);
                console.log('Adding ' + JSON.stringify(dest));

                setTarget(allFiles);
                if (typeof onSave === 'function') {
                    console.log('Saving ' + JSON.stringify(allFiles));
                    onSave(allFiles);
                }
            }
            
          }).catch(error => {
            console.log('Error uploading files. Please try again or consult administrator if problem persists.')
            setProgressShow(false);
            setErrorOpen(true);
        }); 
        console.log('Uploading files and showing progress now...');
    };

    const onErrorClose = () => {
        setErrorOpen(false);
    };


    const onOkClick = () => {
        const localFiles = target.filter( item => item.origin === 'F');
        if ( localFiles.length > 0 ) {
            uploadFiles();
        } else {
            if (typeof onSave === 'function') {
                onSave(target);
            }
        }
    };


    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    };

    const handleSelectionChange = (sel,index) => {
        setSelected(sel);
    };

    const handleAddClick = (event) => {
        const dest = [...target];
        selected.forEach( item => {
            const ditem = dest.find( file => file.path === item.path);
            if ( ! ditem ) {
                let titem = { ...item}; // make a copy so we can change the label without changing original
                titem.origin = tabIndex === 1 ? 'W' : ( tabIndex === 2 ? 'F' : 'D');
                dest.push(titem);
            }
        });
        setTarget(dest);
    };

    const handleDeleteTarget = () => {
        const rtarget = [...target];
        const starget = [...selectedTarget];
        starget.sort();
        for(let i=starget.length-1; i>=0; i--) {
            rtarget.splice(starget[i], 1);
        }
        setTarget(rtarget);
        setSelectedTarget([]);
    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleFileChange = (newFile) => {
        setFiles(newFile);
        const fs = [];
        const upid = uuidv4();
        newFile.forEach( (f) => {
            fs.push({
                origin: 'F',
                path: `/opt/UCS/dlib/uploads/${upid}/${f.name}` ,
                label: f.name,
                file: f,
                uid: upid,
            })
        });
        setSelected(fs);
        console.log('File changed: ' + newFile);
      }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    [theme.breakpoints.up('lg')]: {
                        width: 1200,
                        minWidth: 1200,
                        minHeight: 900
                    },
                    [theme.breakpoints.down('lg')]: {
                        width: '100%',
                        minWidth: '100%',
                        minHeight: '90%'
                    },
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 'bold' }}>Select PDF Files</DialogTitle>
            <DialogContent>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabIndex} onChange={handleTabChange} aria-label="pdf-file-origin">
                        <Tab label="Drafts" {...a11yProps(0)} />
                        <Tab label="Other Workflows" {...a11yProps(1)} />
                        <Tab label="Local File System" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel value={tabIndex} index={0}>
                    <TableContainer component={Paper} sx={{ maxHeight: '350px', height: '310px'}}>
                        <Table stickyHeader aria-label="collapsible table" >
                            <TableHead>
                                <TableRow sx={{
                                    '& > *': {
                                        borderTop: '1px solid rgba(224, 224, 224, 1)', padding: '0.9ex',
                                        [theme.breakpoints.up('lg')]: { borderTop: '1px solid rgba(224, 224, 224, 1)', padding: '0.9ex', },
                                        [theme.breakpoints.down('lg')]: { borderTop: '1px solid rgba(224, 224, 224, 1)', padding: '0.9ex', }
                                    }
                                }}>
                                    <TableCell sx={{
                                        padding: '0.9ex',
                                        [theme.breakpoints.up('lg')]: { padding: '0.9ex', },
                                        [theme.breakpoints.down('lg')]: { padding: '0.9ex', }
                                    }} />
                                    <TableCell component="th" sx={{
                                        fontWeight: 'bold', padding: '0.9ex',
                                        [theme.breakpoints.up('lg')]: { padding: '0.9ex', },
                                        [theme.breakpoints.down('lg')]: { padding: '0.9ex', }
                                    }}>Draft</TableCell>
                                    <TableCell component="th" sx={{
                                        fontWeight: 'bold', padding: '0.9ex',
                                        [theme.breakpoints.up('lg')]: { padding: '0.9ex', },
                                        [theme.breakpoints.down('lg')]: { padding: '0.9ex', }
                                    }}>Date</TableCell>
                                    <TableCell component="th" sx={{
                                        fontWeight: 'bold', padding: '0.9ex',
                                        [theme.breakpoints.up('lg')]: { padding: '0.9ex', },
                                        [theme.breakpoints.down('lg')]: { padding: '0.9ex', }
                                    }}>Filter</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {drafts.map((row, index) => (
                                    <SourceRow key={row.docName + index} row={row} onSelectionChange={(sel) => handleSelectionChange(sel, index)} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <TableContainer component={Paper} sx={{ maxHeight: '350px', height: '310px'}}>
                        <Table stickyHeader aria-label="collapsible table" >
                            <TableHead>
                                <TableRow sx={{
                                    '& > *': {
                                        borderTop: '1px solid rgba(224, 224, 224, 1)', padding: '0.9ex',
                                        [theme.breakpoints.up('lg')]: { borderTop: '1px solid rgba(224, 224, 224, 1)', padding: '0.9ex', },
                                        [theme.breakpoints.down('lg')]: { borderTop: '1px solid rgba(224, 224, 224, 1)', padding: '0.9ex', }
                                    }
                                }}>
                                    <TableCell sx={{
                                        padding: '0.9ex',
                                        [theme.breakpoints.up('lg')]: { padding: '0.9ex', },
                                        [theme.breakpoints.down('lg')]: { padding: '0.9ex', }
                                    }} />
                                    <TableCell component="th" sx={{
                                        fontWeight: 'bold', padding: '0.9ex',
                                        [theme.breakpoints.up('lg')]: { padding: '0.9ex', },
                                        [theme.breakpoints.down('lg')]: { padding: '0.9ex', }
                                    }}>Worflow</TableCell>
                                    <TableCell component="th" sx={{
                                        fontWeight: 'bold', padding: '0.9ex',
                                        [theme.breakpoints.up('lg')]: { padding: '0.9ex', },
                                        [theme.breakpoints.down('lg')]: { padding: '0.9ex', }
                                    }}>Date</TableCell>
                                    <TableCell component="th" sx={{
                                        fontWeight: 'bold', padding: '0.9ex',
                                        [theme.breakpoints.up('lg')]: { padding: '0.9ex', },
                                        [theme.breakpoints.down('lg')]: { padding: '0.9ex', }
                                    }}>Version</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {prevInstances.map((row, index) => (
                                    <SourceWorkflowRow key={row.name + index} row={row} onSelectionChange={(sel) => handleSelectionChange(sel, index)} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
                <TabPanel value={tabIndex} index={2}>
                    <Box sx={{height: '310px', display: 'flex', justifyContent: 'center', alignItems: 'stretch'}}>
                        <FileUpload buttonText='Select' title="Drag 'n' drop PDF files here, or click to select files" accept={{'application/pdf': ['.pdf'],}}
                            value={files} onChange={handleFileChange} sx={{border: 'none', width: '100%', height: '100%'}}/>
                    </Box>
                </TabPanel>
                <Box sx={{ textAlign: 'center', padding: '1em' }}>
                    <Tooltip title="Add file">
                        <span>
                            <IconButton onClick={handleAddClick} disabled={!Array.isArray(selected) || selected.length === 0}>
                                <ForwardIcon sx={{ transform: 'rotate(90deg)' }} />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
                <TableContainer component={Paper} sx={{ height: '300px',  }}>
                    <Table stickyHeader aria-label="collapsible table" >
                        <TableHead>
                            <TableRow sx={{'& > *': { [theme.breakpoints.up('lg')]: { borderTop: '1px solid rgba(224, 224, 224, 1)', padding: '0.9ex' }},
                         [theme.breakpoints.down('lg')]: {borderTop: '1px solid rgba(224, 224, 224, 1)', padding: '0.9ex'} }}>
                                <TableCell component="th" sx={{ fontWeight: 'bold', padding: '0.9ex',  
                                    [theme.breakpoints.up('lg')]: { borderTop: '1px solid rgba(224, 224, 224, 1)', padding: '0.9ex' },
                                    [theme.breakpoints.down('lg')]: {borderTop: '1px solid rgba(224, 224, 224, 1)', padding: '0.9ex'} }}>File</TableCell>
                                <TableCell component="th" sx={{ fontWeight: 'bold', padding: '0.9ex',  
                                    [theme.breakpoints.up('lg')]: { borderTop: '1px solid rgba(224, 224, 224, 1)', padding: '0.9ex' },
                                    [theme.breakpoints.down('lg')]: {borderTop: '1px solid rgba(224, 224, 224, 1)', padding: '0.9ex'} }}>Label</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {target.map((row, index) => (
                                <TargetRow key={row.label + index} file={row} itemSelected={selectedTarget.includes(index)} onItemClick={(event) => handleItemClick(event,index)}/>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions sx={{padding: 3, justifyContent: 'space-between'}}>
                <Box>
                    <Tooltip title="Delete selected files">
                        <span>
                            <IconButton disabled={!Array.isArray(selectedTarget) || selectedTarget.length === 0} onClick={handleDeleteTarget}>
                                <DeleteIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
                <Box sx={{visibility: progressShow ? 'visible' : 'hidden', minWidth: '500px'}}>
                    <LinearProgress variant="determinate" value={progressValue} />
                </Box>
                <Box>
                    <Button variant="outlined" onClick={onOkClick} sx={{ minWidth: '7em' }} disabled={!Array.isArray(target) || target.length === 0}>Set</Button>
                    <Button variant="outlined" onClick={onCancelClick} sx={{ marginLeft: '1em', minWidth: '7em' }}>Cancel</Button>
                </Box>
                <ErrorDialog open={errorOpen} title={errorTitle} message={errorMessage} onClose={onErrorClose} />
            </DialogActions>
        </Dialog>

    );

}

function SimpleUploadFileDialog(props) {
    const {
        open,
        onCancel,
        onSave,
        workflowInstance,
        stepInstance,
        targetFiles,
        description,
        shelf,
        okLabel='Upload',
        acceptFiles={ 'application/pdf': ['.pdf'], },
        acceptLabel="Drag 'n' drop PDF files here, or click to select files",
        ...other
    } = props;

    const [selected, setSelected] = React.useState([]);
    const [target, setTarget] = React.useState([]);
    const [selectedTarget, setSelectedTarget] = React.useState([]);
    const [files, setFiles] = React.useState([]);
    const [desc, setDesc] = React.useState('');
    const [shelfId, setShelfId] = React.useState(DateTime.now().toFormat(SHELF_FORMAT));
    const [progressValue, setProgressValue] = React.useState([]);
    const [progressFactor, setProgressFactor] = React.useState(1);
    const [progressShow, setProgressShow] = React.useState(false);
    const [aborted, setAborted] = React.useState(false);
    const fileUploadProgress = useSelector((state) => state.fileUpload.progress);
    const [errorOpen, setErrorOpen] = React.useState(false);
    const [errorTitle, setErrorTitle] = React.useState('Upload Error');
    const [errorMessage, setErrorMessage] = React.useState('An error occurred uploading files. Please try again or consult administrator if problem persists.');

    const theme = useTheme();

    const ABORTED_MESSAGE = 'Aborted';

    React.useEffect(() => {
        setFiles([]);
        setErrorOpen(false);
        if ( Array.isArray(targetFiles) ) {
            setTarget(targetFiles);
        } else if ( open ) {
            setTarget([]);
        }
        if ( description ) {
            setDesc(description);
        } else {
            setDesc('');
        }
        if ( open) {
            setAborted(false);
            ABORTED=false;
        }
        if ( shelf && shelf !== '') {
            setShelfId(shelf);
        } else {
            setShelfId(DateTime.now().toFormat(SHELF_FORMAT));
        }
    }, [workflowInstance, stepInstance, open, targetFiles, shelf, description]);

    React.useEffect( ()=> {
        setProgressValue(fileUploadProgress / progressFactor);
    }, [fileUploadProgress, progressFactor]);



    const uploadFiles = () => {
        const allFiles = [];
        const localFiles = [];
        

        target.forEach( item => {
            if ( item.origin === 'F' && !item.uploaded ) {
                localFiles.push(item);
                // netUpload(item.file, `/api/upload/${item.uid}?dst=${item.path}&uid=${item.uid}`);
            } else {
                allFiles.push(item);
            }

        });
        console.log('Local files: ' + localFiles.length);
        setAborted(false);
        
        setProgressShow(true);
        if ( localFiles.length > 0 ) {
            setProgressFactor(localFiles.length);
        } else {
            setProgressFactor(1);
        }
        setProgressValue(0);
        localFiles.reduce(function (prev, item) {
            return prev.then((value) => {
                if ( ABORTED ) {
                    console.log('Aborting ...');
                    return Promise.reject(ABORTED_MESSAGE);
                } else if (value.status >= 400) {
                    return Promise.reject(value.statusText);
                } else {
                    if (value.token) {
                        // console.log('value token1: ' + JSON.stringify(value.token));
                        const dest = {
                            path: value.token.path,
                            label: value.token.label,
                            uid: value.token.uid,
                            origin: 'F',
                        };
                        allFiles.push(dest);
                        // console.log('Adding ' + JSON.stringify(dest));
                    }
                    return netUpload(item.file, `/api/upload/${item.uid}?dst=${item.path}&uid=${item.uid}`, item);
                }
            });
        }, Promise.resolve({})).then((value) => {
            if ( ABORTED ) {
                console.log('Aborting ...');
                return Promise.reject(ABORTED_MESSAGE);
            } else if (value.status >= 400) {
                return Promise.reject(value.statusText);
            } else if (value.token) {
                // console.log('value token2: ' + JSON.stringify(value.token));
                const dest = {
                    path: value.token.path,
                    label: value.token.label,
                    uid: value.token.uid,
                    origin: 'F',
                    uploaded: true,
                };
                allFiles.push(dest);
                setProgressShow(false);
                // console.log('Adding ' + JSON.stringify(dest));

                setTarget(allFiles);
                if (typeof onSave === 'function') {
                    // console.log('Saving ' + JSON.stringify(allFiles));
                    onSave(allFiles, desc, shelfId);
                }
            }

        }).catch(error => {
            console.log('Error uploading files. Please try again or consult administrator if problem persists: ' + error);
            setProgressShow(false);
            setErrorOpen(true);
        }); 
        console.log('Uploading files and showing progress now...');
    };

    const onErrorClose = () => {
        setErrorOpen(false);
    };


    const onOkClick = () => {
        ABORTED = false;
        const localFiles = target.filter( item => item.origin === 'F'  && !item.uploaded );
        if ( localFiles.length > 0 ) {
            uploadFiles();
            // setTimeout(uploadFiles, 4000);
        } else {
            if (typeof onSave === 'function') {
                onSave(target, desc, shelfId);
            }
        }
    };

    const onCancelClick = () => {
        setAborted(true);
        ABORTED = true;
        console.log('ABORTING...');
        onClose();
    };

    const onClose = () => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    };

    const handleFileChange = (newFile) => {
        // setFiles(newFile);
        const rawFiles = [...files];
        newFile.forEach( f => {
            const oldf = rawFiles.find( rf => rf.name === f.name);
            if ( !oldf ) {
                rawFiles.push(f);
            } else {
                console.log(f.name + ' already added.');
            }
        });
        const fs = [];
        const upid = shelfId;
        console.log('shelfId: ' + upid);
        rawFiles.forEach((f) => {
            fs.push({
                origin: 'F',
                path: `/opt/UCS/dlib/uploads/${upid}/${f.name}`,
                label: f.name,
                file: f,
                uid: upid,
            })
        });
        setFiles(rawFiles);
        setSelected(fs);
        setTarget(fs);
        console.log('File changed: ' + newFile);
    };

    const handleFileRemove = (newFiles) => {
        setFiles(newFiles);
        const fs = [];
        const upid = shelfId;
        console.log('shelfId: ' + upid);
        newFiles.forEach((f) => {
            fs.push({
                origin: 'F',
                path: `/opt/UCS/dlib/uploads/${upid}/${f.name}`,
                label: f.name,
                file: f,
                uid: upid,
            })
        });
        setSelected(fs);
        setTarget(fs);
    }

    const handleDescriptionChange = (event) => {
        setDesc(event.target.value);
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    [theme.breakpoints.up('lg')]: {
                        width: 1000,
                        minWidth: 1000,
                        minHeight: 800
                    },
                    [theme.breakpoints.down('lg')]: {
                        width: '100%',
                        minWidth: '100%',
                        minHeight: '90%'
                    },
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 'bold' }}>Select Files to Upload</DialogTitle>
            <DialogContent sx={{height: '100%', display: 'grid', gridTemplateRows: '1fr min-content',  position: 'relative', padding: 3}}>
                <Box sx={{  width: '100%', display: 'flex', alignContent: 'stretch', justifyContent: 'center', alignItems: 'stretch', position: 'relative', overflow: 'auto', 
                        
                 }}>
                    <FileUpload buttonText='Select' title={acceptLabel} accept={acceptFiles}
                        value={files} onChange={handleFileChange} onRemove={handleFileRemove} sx={{ width: '100%' }} multiple maxFiles={100} />
                </Box>
                <Box sx={{width: '100%', padding: 3, paddingLeft: 0, paddingRight: 0}}>
                    <TextField value={desc} onChange={handleDescriptionChange} label="Description" size="small" sx={{width: '100%'}}/>
                </Box>
            </DialogContent>
            <DialogActions sx={{padding: 3, justifyContent: 'space-between'}}>
                <Box sx={{visibility: progressShow ? 'visible' : 'hidden', minWidth: '500px'}}>
                    <LinearProgress variant="determinate" value={progressValue} />
                </Box>
                <Box>
                    <Button variant="outlined" onClick={onOkClick} sx={{ minWidth: '7em' }} disabled={!Array.isArray(target) || target.length === 0}>{okLabel}</Button>
                    <Button variant="outlined" onClick={onCancelClick} sx={{ marginLeft: '1em', minWidth: '7em' }}>Cancel</Button>
                </Box>
                <ErrorDialog open={errorOpen} title={errorTitle} message={errorMessage} onClose={onErrorClose} />
            </DialogActions>
        </Dialog>

    );

}

function UploadFileDialog(props) {
    const {
        open,
        onCancel,
        onSave,
        workflowInstance,
        stepInstance,
        targetFiles,
        ...other
    } = props;

    const [selected, setSelected] = React.useState([]);
    const [target, setTarget] = React.useState([]);
    const [selectedTarget, setSelectedTarget] = React.useState([]);
    const [files, setFiles] = React.useState([]);
    const [progressValue, setProgressValue] = React.useState([]);
    const [progressFactor, setProgressFactor] = React.useState(1);
    const [progressShow, setProgressShow] = React.useState(false);
    const fileUploadProgress = useSelector((state) => state.fileUpload.progress);
    const [errorOpen, setErrorOpen] = React.useState(false);
    const [errorTitle, setErrorTitle] = React.useState('Upload Error');
    const [errorMessage, setErrorMessage] = React.useState('An error occurred uploading files. Please try again or consult administrator if problem persists.');

    const theme = useTheme();

    React.useEffect(() => {
        setFiles([]);
        setErrorOpen(false);
        if ( Array.isArray(targetFiles) ) {
            setTarget(targetFiles);
        } else if ( open ) {
            setTarget([]);
        }
    }, [workflowInstance, stepInstance, open, targetFiles]);

    React.useEffect( ()=> {
        setProgressValue(fileUploadProgress / progressFactor);
    }, [fileUploadProgress, progressFactor]);

    const doSingleClick = (sel, index) => {
        if ( sel.length === 1 && sel.includes(index) ) {
            const i = sel.indexOf(index);
            sel.splice(i,1);
            setSelectedTarget(sel);
        } else {
            sel.splice(0, sel.length);
            sel.push(index);
            setSelectedTarget(sel);
        }
    };

    const handleItemClick = (event, index) => {
        // handle Ctrl and Shift clicks for multiple selections
        // console.log('ctrl: ' + event.ctrlKey + ' shift: ' + event.shiftKey);
        const sel = [ ...selectedTarget];
        if ( event.ctrlKey || event.metaKey) {   // MacOS cmd key maps to metaKey on modern browsers
            if ( sel.includes(index) ) {
                const i = sel.indexOf(index);
                sel.splice(i,1);
            } else {
                sel.push(index);
            }
            setSelectedTarget(sel);
        } else if ( event.shiftKey ) {
            if ( sel.length > 0 ) {
                // add all items from last index
                const last = sel[sel.length -1];
                if ( index === last ) {
                    doSingleClick(sel, index);
                } else if ( index > last ) {
                    for(let i=last+1; i<=index; i++) {
                        sel.push(i);
                    }
                    setSelectedTarget(sel);
                } else {
                    for(let i=last-1; i>=index; i--) {
                        sel.push(i);
                    }
                    setSelectedTarget(sel);
                }
            } else {
                doSingleClick(sel, index);
            }
        } else {
            doSingleClick(sel, index);
        }
    };

    const uploadFiles = () => {
        const allFiles = [];
        const localFiles = [];
        

        target.forEach( item => {
            if ( item.origin === 'F' && !item.uploaded ) {
                localFiles.push(item);
                // netUpload(item.file, `/api/upload/${item.uid}?dst=${item.path}&uid=${item.uid}`);
            } else {
                allFiles.push(item);
            }

        });
        console.log('Local files: ' + localFiles.length);
        setProgressShow(true);
        if ( localFiles.length > 0 ) {
            setProgressFactor(localFiles.length);
        } else {
            setProgressFactor(1);
        }
        setProgressValue(0);
        localFiles.reduce(function (prev, item) {
            return prev.then((value) => {
                if (value.status >= 400) {
                    return Promise.reject(value.statusText);
                } else {
                    if (value.token) {
                        console.log('value token1: ' + JSON.stringify(value.token));
                        const dest = {
                            path: value.token.path,
                            label: value.token.label,
                            uid: value.token.uid,
                            origin: 'F',
                        };
                        allFiles.push(dest);
                        console.log('Adding ' + JSON.stringify(dest));
                    }
                    return netUpload(item.file, `/api/upload/${item.uid}?dst=${item.path}&uid=${item.uid}`, item);
                }
            });
        }, Promise.resolve({})).then((value) => {
            if (value.status >= 400) {
                return Promise.reject(value.statusText);
            } else if (value.token) {
                console.log('value token2: ' + JSON.stringify(value.token));
                const dest = {
                    path: value.token.path,
                    label: value.token.label,
                    uid: value.token.uid,
                    origin: 'F',
                    uploaded: true,
                };
                allFiles.push(dest);
                setProgressShow(false);
                console.log('Adding ' + JSON.stringify(dest));

                setTarget(allFiles);
                if (typeof onSave === 'function') {
                    console.log('Saving ' + JSON.stringify(allFiles));
                    onSave(allFiles);
                }
            }

        }).catch(error => {
            console.log('Error uploading files. Please try again or consult administrator if problem persists: ' + error);
            setProgressShow(false);
            setErrorOpen(true);
        }); 
        console.log('Uploading files and showing progress now...');
    };

    const onErrorClose = () => {
        setErrorOpen(false);
    };


    const onOkClick = () => {
        const localFiles = target.filter( item => item.origin === 'F'  && !item.uploaded );
        if ( localFiles.length > 0 ) {
            uploadFiles();
        } else {
            if (typeof onSave === 'function') {
                onSave(target);
            }
        }
    };

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    };

    const handleSelectionChange = (sel,index) => {
        setSelected(sel);
    };

    const handleAddClick = (event) => {
        const dest = [...target];
        selected.forEach( item => {
            const ditem = dest.find( file => file.path === item.path);
            if ( ! ditem ) {
                let titem = { ...item}; // make a copy so we can change the label without changing original
                titem.origin =  'F';
                titem.uploaded = false;
                dest.push(titem);
            }
        });
        setTarget(dest);
    };

    const handleDeleteTarget = () => {
        const rtarget = [...target];
        const starget = [...selectedTarget];
        starget.sort();
        for(let i=starget.length-1; i>=0; i--) {
            rtarget.splice(starget[i], 1);
        }
        setTarget(rtarget);
        setSelectedTarget([]);
    };

    const handleFileChange = (newFile) => {
        setFiles(newFile);
        const fs = [];
        const upid = uuidv4();
        newFile.forEach((f) => {
            fs.push({
                origin: 'F',
                path: `/opt/UCS/dlib/uploads/${upid}/${f.name}`,
                label: f.name,
                file: f,
                uid: upid,
            })
        });
        setSelected(fs);
        console.log('File changed: ' + newFile);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    [theme.breakpoints.up('lg')]: {
                        width: 1200,
                        minWidth: 1200,
                        minHeight: 900
                    },
                    [theme.breakpoints.down('lg')]: {
                        width: '100%',
                        minWidth: '100%',
                        minHeight: '90%'
                    },
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 'bold' }}>Select Files to Upload</DialogTitle>
            <DialogContent>
                <Box sx={{ height: '310px', display: 'flex', justifyContent: 'center', alignItems: 'stretch', position: 'relative', overflow: 'auto' }}>
                    <FileUpload buttonText='Select' title="Drag 'n' drop PDF files here, or click to select files" accept={{ 'application/pdf': ['.pdf'], }}
                        value={files} onChange={handleFileChange} sx={{ border: 'none', width: '100%', height: '50%' }} />
                </Box>
                <Box sx={{ textAlign: 'center', padding: '1em' }}>
                    <Tooltip title="Add file">
                        <span>
                            <IconButton onClick={handleAddClick} disabled={!Array.isArray(selected) || selected.length === 0}>
                                <ForwardIcon sx={{ transform: 'rotate(90deg)' }} />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
                <TableContainer component={Paper} sx={{ height: '300px', }}>
                    <Table stickyHeader aria-label="collapsible table" >
                        <TableHead>
                            <TableRow sx={{
                                '& > *': { [theme.breakpoints.up('lg')]: { borderTop: '1px solid rgba(224, 224, 224, 1)', padding: '0.9ex' } },
                                [theme.breakpoints.down('lg')]: { borderTop: '1px solid rgba(224, 224, 224, 1)', padding: '0.9ex' }
                            }}>
                                <TableCell component="th" sx={{
                                    fontWeight: 'bold', padding: '0.9ex',
                                    [theme.breakpoints.up('lg')]: { borderTop: '1px solid rgba(224, 224, 224, 1)', padding: '0.9ex' },
                                    [theme.breakpoints.down('lg')]: { borderTop: '1px solid rgba(224, 224, 224, 1)', padding: '0.9ex' }
                                }}>File</TableCell>
                                <TableCell component="th" sx={{
                                    fontWeight: 'bold', padding: '0.9ex',
                                    [theme.breakpoints.up('lg')]: { borderTop: '1px solid rgba(224, 224, 224, 1)', padding: '0.9ex' },
                                    [theme.breakpoints.down('lg')]: { borderTop: '1px solid rgba(224, 224, 224, 1)', padding: '0.9ex' }
                                }}>Label</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {target.map((row, index) => (
                                <TargetRow key={row.label + index} file={row} itemSelected={selectedTarget.includes(index)} onItemClick={(event) => handleItemClick(event, index)} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions sx={{padding: 3, justifyContent: 'space-between'}}>
                <Box>
                    <Tooltip title="Delete selected files">
                        <span>
                            <IconButton disabled={!Array.isArray(selectedTarget) || selectedTarget.length === 0} onClick={handleDeleteTarget}>
                                <DeleteIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
                <Box sx={{visibility: progressShow ? 'visible' : 'hidden', minWidth: '500px'}}>
                    <LinearProgress variant="determinate" value={progressValue} />
                </Box>
                <Box>
                    <Button variant="outlined" onClick={onOkClick} sx={{ minWidth: '7em' }} disabled={!Array.isArray(target) || target.length === 0}>Set</Button>
                    <Button variant="outlined" onClick={onCancelClick} sx={{ marginLeft: '1em', minWidth: '7em' }}>Cancel</Button>
                </Box>
                <ErrorDialog open={errorOpen} title={errorTitle} message={errorMessage} onClose={onErrorClose} />
            </DialogActions>
        </Dialog>

    );

}

function ViewDraftOutputDialog (props) {
    const {
        open,
        onCancel,
        onSave,
        workflowInstance,
        stepInstance,
        isEdition=false,
        title='Draft Outputs',
        ...other
    } = props;

    const [files, setFiles] = React.useState([]);
    const [target, setTarget] = React.useState({});
    const [selectedIndex, setSelectedIndex] = React.useState(-1);

    const theme = useTheme();


    React.useEffect(() => {
        setFiles([]);
        netPost('/api/workflow/step/inst/info', stepInstance)
            .then(response => response.json())
            .then(info => {
                // console.log("ViewDraft info: " + JSON.stringify(info));
                if (info && Array.isArray(info.files)) {
                    // console.log('PDF Drafts ' + JSON.stringify(info.files));
                    setFiles(info.files);
                    
                } else {
                    // console.log('PDF Drafts []');
                    setFiles([]);
                }
                
            }).catch(error => console.log('Error: ' + error));
        // setFiles([]);
        setSelectedIndex(-1);
        setTarget('');
        // setErrorOpen(false);
    }, [workflowInstance, stepInstance, open]);

    const onOkClick = () => {
        if (typeof onSave === 'function') {
            onSave(target);
        }
    };

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    };

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
        if ( index >= 0 ) {
            const item = files[index];
            setTarget(item);
        }
        if ( event.detail > 1 ) {
            if (typeof onSave === 'function') {
                const item = files[index];
                onSave(item);
            }
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    [theme.breakpoints.up('lg')]: {
                        width: 800,
                        minWidth: 800,
                        minHeight: 600
                    },
                    [theme.breakpoints.down('lg')]: {
                        width: '100%',
                        minWidth: '100%',
                        minHeight: '90%'
                    },
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 'bold' }}>{title}</DialogTitle>
            <DialogContent>
                {files && files.length > 0 &&
                    <List dense={true}>
                        {files.map((item, index) => (
                            <ListItemButton key={`file-${index}`} selected={index === selectedIndex}
                                onClick={(event) => handleListItemClick(event, index)}>
                                <ListItemText
                                    primary={item.label}
                                />
                            </ListItemButton>
                        ))}
                    </List>
                }
                { (!files || files.length === 0) &&
                    <Typography sx={{paddingTop: '2em', fontSize: '130%'}}>No Outputs generated.</Typography>
                }
            </DialogContent>
            <DialogActions sx={{padding: 3}}>
                <Box>
                    <Button variant="outlined" onClick={onOkClick} sx={{ minWidth: '7em' }} disabled={selectedIndex < 0}>View</Button>
                    <Button variant="outlined" onClick={onCancelClick} sx={{ marginLeft: '1em', minWidth: '7em' }}>Cancel</Button>
                </Box>
            </DialogActions>
        </Dialog>

    );
};

function AbortConfirmDialog(props) {
    const {
        title = 'Confirm Action',
        message = 'Abort process?',
        open,
        index,
        onCancel,
        onAbort,
        ...other
    } = props;

    const onOKClick = () => {
        if ( typeof onAbort === 'function' ) {
            onAbort(index);
        }
    };

    const onCancelClick = () => {
        if ( typeof onCancel === 'function' ) {
            onCancel();
        }
    };

    const onLocalClose = () => {
        if ( typeof onCancel === 'function' ) {
            onCancel();
        }
    };

    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onLocalClose}
            PaperProps={{
                sx: {
                  minWidth: 500,
                  maxHeight: 600
                }
              }}
        >
            <DialogTitle sx={{fontWeight: 'bold', backgroundColor: '#d32f2f'}}>{title}</DialogTitle>
            <DialogContent>
                
                <Box sx={{paddingTop: '2ex'}}>
                    <Typography>
                        {message}
                    </Typography>
                </Box>
                
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onOKClick} sx={{minWidth: '7em'}} >Abort</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{minWidth: '7em'}} >Cancel</Button>
            </DialogActions>
        </Dialog>

    );

}

export { SelectPdfDraftDialog, UploadFileDialog, SimpleUploadFileDialog, ViewDraftOutputDialog, AbortConfirmDialog };