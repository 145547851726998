import { createSlice } from '@reduxjs/toolkit'

/**
 * State of the menu system in order to isolate UI changes comming from backend notifications.
 */
export const menu = createSlice({
    name: 'menu',
    initialState: {
        currentMenu: '',
        pageIndex: 0,
        pageName: '',
    },
    reducers: {
        setCurrentMenu: (state, action) => {
            state.currentMenu = action.payload;
            // console.log('Setting currentMenu = ' + action.payload + ', pageIndex = ' + state.pageIndex);
        },
        setPageIndex: (state, action) => {
            state.pageIndex = action.payload;
            // onsole.log('Setting pageIndex = ' + action.payload + ', currentMenu = ' + state.currentMenu);
        },
        setPageName: (state, action) => {
            state.pageName = action.payload
        },
    },
});

export const workfront = createSlice({
    name: 'workfront',
    initialState: {
        listValue: true,
        showList: true,
        instanceValue: false,
        showInstance: false,
        instanceNode: null,
        expandedKeys: {},
    },
    reducers: {
        setWorkList: (state, action) => {
            state.showList = action.payload.showList;
            state.listValue = action.payload.listValue;
            // console.log('Setting currentMenu = ' + action.payload + ', pageIndex = ' + state.pageIndex);
        },
        setWorkInstance: (state, action) => {
            state.showInstance = action.payload.showInstance;
            state.instanceValue = action.payload.instanceValue;
            
            // onsole.log('Setting pageIndex = ' + action.payload + ', currentMenu = ' + state.currentMenu);
        },
        setWorkNode: (state,action) => {
            state.instanceNode = action.payload
        },
        setExpandedKeys: (state,action) => {
            state.expandedKeys = action.payload
        },
    },
});

export const { setCurrentMenu, setPageIndex, setPageName } = menu.actions;
export const { setWorkInstance, setWorkList, setWorkNode, setExpandedKeys } = workfront.actions;