import React, { Component, useEffect, useState } from "react";
import { styled, useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IconButton from '@mui/material/IconButton';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';


import { UserInfo, netGet, netPost, netFetch, keycloak } from "../network";

const FLEET_COLOR = '#79a6d2';
const FLEET_COLOR_DARKER = '#19334d';

dayjs.extend(utc);
dayjs.extend(timezone);


function SupplementOutputs (props) {
    const {
        fields,
        values,
        index,
        doc,
        onChange,
        ...other
    } = props;
    const [suppList, setSuppList] = useState([]);
    const [audience, setAudience] = useState('CC');
    const [suppMap, setSuppMap] = useState({});

    const SUPP_SEL_LABEL = 'Select Supplement 1';
    const SUPP_SEL_OUT_LABEL = 'Select Fleet Output';
    const MULTIPLE = true;

    useEffect(() => {
        netGet(`/api/dlib/supps/list?did=${doc}&wfiles=1&uncheck=1`)
            .then(response => response.json())
            .then(list => {
                const binders = list.filter( b => Array.isArray(b.files) && b.files.length > 0);
                // console.log('Supplement binders: ' + JSON.stringify(binders));
                if ( Array.isArray(binders)) {
                    setSuppList(list);
                    // console.log('Supplement binders: ' + JSON.stringify(list));
                    if ( values[index]?.value ) {
                        loadSupplement(values[index]?.value);
                    }
                    if ( values[index]?.value?.file ) {
                        const file = values[index].value.file;
                        if ( file?.name  ) {
                            if ( file.name.includes('CC-')) {
                                setAudience('CC');
                            } else {
                                setAudience('FC');
                            }
                        } else {
                            setAudience('CC');
                        }
                    } else if ( Array.isArray(values[index]?.value?.files) && values[index].value.files.length > 0 ) {
                        const file = values[index].value.files[0];
                        if ( file?.name  ) {
                            if ( file.name.includes('CC-')) {
                                setAudience('CC');
                            } else {
                                setAudience('FC');
                            }
                        } else {
                            setAudience('CC');
                        }
                    }
                }
            }).catch(error => console.log('Error retrieving supplements list: ' + error));

    },[fields,values]);

    const onAudienceChange = (event) => {
        setAudience(event.target.value);
        if ( typeof onChange === 'function') {
            onChange(null);
        }

    };

    const onSupplementChange = (event, value, fields, index) => {
        // console.log('Supplement SELECTED: ' + JSON.stringify(value));
        loadSupplement(value);
        if ( typeof onChange === 'function') {
            onChange(value);
        }
    };

    const loadSupplement = ( value ) => {
        if ( value?.name ) {
            if ( !suppMap[value.name] ) {
                netGet(`/api/doc/supp/info/${doc}/${value.name}?f=1&d=1`)
                    .then(response => response.json())
                    .then(data => {
                        const sm = { ...suppMap};
                        sm[value.name] = data;
                        setSuppMap(sm);
                        // console.log('Selected SUPP: ' + JSON.stringify(data?.properties?.files));
                        if ( Array.isArray(data?.properties?.files) ) {
                            if ( data.properties.files.length === 1 || (data.properties.files.length > 0 && MULTIPLE) ) {
                                const vv = values[index]?.value;
                                if (vv) {
                                    const vvn = { ...vv };
                                    const file = data.properties.files[0];
                                    if ( MULTIPLE ) {
                                        vvn.files = [...data.properties.files];
                                    } else {
                                        vvn.file = file;
                                    }
                                    if ( data.properties.drafts) {
                                        vvn.drafts = data.properties.drafts;
                                    }
                                    // console.log('SUPP DRAFTS: ' + JSON.stringify(vvn.drafts));
                                    if ( file?.name  ) {
                                        if ( file.name.includes('CC-')) {
                                            setAudience('CC');
                                        } else {
                                            setAudience('FC');
                                        }
                                    } else {
                                        setAudience('CC');
                                    }
                                    if (typeof onChange === 'function') {
                                        onChange(vvn);
                                    }
                                }
                            }
                        }
                    }).catch(error => console.log('Error retrieving supplement info: ' + error));
            } else {
                const data = suppMap[value.name];
                if ( Array.isArray(data?.properties?.files) ) {
                    if ( data.properties.files.length === 1 || (data.properties.files.length > 0 && MULTIPLE) ) {
                        const vv = values[index]?.value;
                        if (vv) {
                            const vvn = { ...vv };
                            const file = data.properties.files[0];
                            if (MULTIPLE) {
                                vvn.files = [...data.properties.files];
                            } else {
                                vvn.file = file;
                            }
                            if ( data.properties.drafts ) {
                                vvn.drafts = data.properties.drafts;
                            }
                            if ( file?.name  ) {
                                if ( file.name.includes('CC-')) {
                                    setAudience('CC');
                                } else {
                                    setAudience('FC');
                                }
                            } else {
                                setAudience('CC');
                            }
                            if (typeof onChange === 'function') {
                                onChange(vvn);
                            }
                        }
                    }
                }
            }
        }
    };

    const supplementEqual = (option, value) => {
        if ( option?.name && value?.name ) {
            return option.name === value.name;
        }
        return false;
    }

    const filter = () => {
        if ( audience === 'CC' ) {
            return suppList.filter( item => item.properties?.CC );
        } else {
            return suppList.filter( item => item.properties?.FC );
        }
    }

    const onOutputChange = (event) => {
        const vv = values[index]?.value;
        if ( vv ) {
            const files = suppMap[values[index].value.name]?.properties?.files;
            if (files) {
                const file = files.find( f => f.name === event.target.value);
                if (file) {
                    const vvn = { ...vv };
                    vvn.file = file;
                    if (typeof onChange === 'function') {
                        onChange(vvn);
                    }
                }
            }
        }
    };
    
    
    return (
        <Box>
            <FormControl>
                <FormLabel id="supp-aud-group-label">Audience</FormLabel>
                <RadioGroup
                    aria-labelledby="supp-aud-group-label"
                    value={audience}
                    name="supp-aud-group"
                    onChange={onAudienceChange}
                    row
                >
                    <FormControlLabel value="CC" control={<Radio />} label="Cabin Crew" />
                    <FormControlLabel value="FC" control={<Radio />} label="Flight Crew" />
                </RadioGroup>
            </FormControl>
            <Autocomplete
                disablePortal
                options={filter()}
                value={values[index]?.value ? values[index].value : null}
                getOptionLabel={(item) => item?.description ? item.description : ''}
                getOptionKey={(item) => item?.name ? item.name : 'undefined'}
                isOptionEqualToValue={supplementEqual}
                fullWidth
                slotProps={{
                    listbox: {
                        sx: { maxHeight: 280 }
                    }
                }}
                size="medium"
                sx={{marginTop: 3}}
                onChange={(event, value) => onSupplementChange(event, value, fields, index)}
                renderInput={(params) => <TextField {...params} label={fields[index].required ? `${SUPP_SEL_LABEL} *` : SUPP_SEL_LABEL} />}
            />
            {!MULTIPLE &&
                <FormControl size="small" sx={{ width: '100%', marginTop: 2 }} required={fields[index].required}>
                    <InputLabel id={`supp-out-select-label-${index}`} size="medium">{SUPP_SEL_OUT_LABEL}</InputLabel>
                    <Select
                        labelId={`supp-out-select-label-${index}`}
                        id={`field-select-${index}`}
                        value={typeof values[index]?.value?.file?.name === 'undefined' ? '' : values[index].value.file.name}
                        label={fields[index].required ? `${SUPP_SEL_OUT_LABEL} *` : SUPP_SEL_OUT_LABEL}
                        onChange={onOutputChange}
                        required={fields[index].required}
                        size="medium"
                    >
                        {values[index]?.value?.name && Array.isArray(suppMap[values[index].value.name]?.properties?.files) &&
                            suppMap[values[index].value.name].properties.files.filter(file => audience === 'CC' ? file.name.includes('CC-') : !file.name.includes('CC-')).map(file =>
                                <MenuItem value={file.name} key={file.name}>{file.properties.number}</MenuItem>
                            )

                        }
                    </Select>
                </FormControl>
            }
        </Box>
    );
}


function SupplementControl (props) {
    const {
        fields, // All fields definitions
        values, // all values
        index, 
        // item,       // current field item
        // itemIndex,  // current field item index
        onChange,
        control,
        initialValue,
        position='right', // to the right of component or top
        align='center',
        binary=false,  // whether the value is boolean (true or false)
    } = props;

    const [fleets, setFleets] = useState([]); // { label, name }
    const [outputs, setOutputs] = useState([]); // { value, fleets[] }
    const [allFleetValue, setAllFleetValue] = useState(true);

    useEffect(() => {
        if ( Array.isArray(fields) && Array.isArray(values) ) {
            // setAllFleetValue(false);
            const ai = fields.findIndex( f => f.name?.toLowerCase().includes('audience'));
            const auds = [];
            if ( ai >= 0 ) {
                const fa = fields[ai];
                const fv = values[ai];
                fa.properties.selectionData.forEach( item => {
                    if ( item.value && fv?.fields) {
                        const iv = fv.fields[item.value];
                        if ( iv ) {
                            const ffi = {
                                label: item.label,
                                name: item.value,
                            };
                            auds.push(ffi);
                        }
                    }
                });
                // console.log('AUD selData = ' + JSON.stringify(fa.properties.selectionData));
                // console.log('AUD value = ' + JSON.stringify(fv));
            }
            // console.log('Audiences: ' + JSON.stringify(auds));

            const fi = fields.findIndex( f => f.name?.toLowerCase().includes('fleet'));
            if ( fi >= 0 ) {
                const fd = fields[fi];
                const fv = values[fi];
                // console.log('FLEETS: ' + JSON.stringify(fd));
                // console.log('F VALUE: ' + JSON.stringify(fv));
                /*
                if ( index < values.length ) {
                    console.log('ITEM VALUE = ' + JSON.stringify(values[index].comp));
                }
                */
                if ( Array.isArray(fd.properties?.selectionData)) {
                    const ff = [];
                    const ff2 = [];
                    const allf = fd.properties.selectionData.find( item => item.value && item.value.includes('ALL') && fv?.fields && fv.fields[item.value]);
                    fd.properties.selectionData.forEach( item => {
                        if ( item.value && !item.value.includes('ALL') &&  fv?.fields) {
                            const iv = fv.fields[item.value];
                            if ( iv || allf) {
                                const ffi = {
                                    label: 'FC-' + item.label,
                                    name: item.value,
                                    value: true,
                                };
                                ff.push(ffi);
                                const ffi2 = {
                                    label: 'CC-' + item.label,
                                    name: 'CC_' + item.value,
                                    value: true,
                                }
                                ff2.push(ffi2);
                            }
                        }
                    });
                    // fleets stores the template
                    /*
                    if ( ff.length == 1) {
                        ff[0].value = true;
                    }
                    */
                    const ffa = [];
                    if ( auds.length > 0 ) {
                        auds.forEach( a => {
                            if ( a.name.includes('CABIN') ) {
                                ff2.forEach( f => ffa.push(f));
                            } else {
                                ff.forEach( f => ffa.push(f) );
                            }
                        });
                    } else {
                        ff.forEach( f => ffa.push(f) );
                        // setFleets(ffa);
                    }
                    if ( ffa.length == 1) {
                        ffa[0].value = true;
                    }
                    setFleets(ffa);
                    // console.log('FLEETS: ' + JSON.stringify(ffa));
                    let outs = [];
                    if ( index < values.length && values[index].comp) {
                        outs = values[index].comp;
                        if ( !Array.isArray(outs) ) {
                            outs = [...outputs];
                        }
                        if ( outs.length > 1 ) {
                            setAllFleetValue(false);
                        } else if ( outs.length === 1) {
                            if ( Array.isArray(outs[0].fleets) ) {
                                let v = true;
                                for(let y=0; y<outs[0].fleets.length; y++) {
                                    const f = outs[0].fleets[y];
                                    if ( f && typeof f.value === 'boolean') {
                                        if ( !f.value ) {
                                            v = false;
                                            break;
                                        }
                                    }
                                }
                                setAllFleetValue(v);
                            }
                        }
                    }
                    let ap = [];
                    if ( outs.length == 0) {
                        // initialize
                        ap = [{
                            value: initialValue,
                            fleets: [...ffa],
                        }];
                        setOutputs(ap);
                        // onFleetValueChanged(ap, 0, ap[0].fleets, 0, true);
                    } else {
                        ap = [...outs];
                        // console.log('OLD APPROVERS: ' + JSON.stringify(approvers));
                        ap.forEach( item => {
                            const ffi = [];
                            ffa.forEach( f => {
                                const ifv = item.fleets.find( i => i.name === f.name);
                                // console.log('FLEET: ' + JSON.stringify(ifv));
                                ffi.push({
                                    label: f.label,
                                    name: f.name,
                                    value: ifv ? ifv.value : true,
                                });
                            })
                            item.fleets = ffi;
                        });
                        setOutputs(ap);
                        
                    }
                    filterOutputs(ap, ffa);
                    
                    // console.log('LIMITED: ' + JSON.stringify(ap));
                }
            }
        }

    },[fields, values]);

    const filterOutputs = (apprs, fleets) => {
        // filter the outputs, the first output sees all fleets,
        // each subsequent output sees the leftover from previous
        // if there are not leftovers, remove the output
        const aprs = [];
        let lff = [...fleets]; // initialize with all fleets
        apprs.forEach ( a => {
            const aff = [];
            lff.forEach(f => {
                aff.push({
                    name: f.name,
                    label: f.label,
                    value: false,
                })
            });
            // copy current value
            if (aff.length > 0) {
                aff.forEach(f => {
                    const af = a.fleets.find(cf => cf.name === f.name);
                    if (af) {
                        f.value = af.value;
                    } else {
                        f.value = false;
                    }
                });

                a.fleets = aff;
                /*
                if ( a.fleets.length == 1) {
                    a.fleets[0].value = true;
                    const af = fleets.find( f => f.name === a.fleets[0].name);
                    if ( af ) {
                        af.value = true;
                    }
                }
                */
                const fs = a.fleets.filter( v => v.value);
                if (fs?.length > 0) {
                    aprs.push(a);
                }
            }
            const nlff = aff.filter( f => f.value === false);
            lff = nlff;
        });
        lff = [...fleets];
        lff.forEach(f => f.value = false);
        apprs.forEach( a => {
            a.fleets.forEach(f => {
                if ( f.value ) {
                    const lf = lff.find( l => l.name === f.name);
                    if ( lf ) {
                        lf.value = f.value;
                    }
                }
            });
        });

        // adjust if only one remaining
        /*
        const rf = lff.filter( f => f.value === false);
        if ( rf.length === 1) {
            rf[0].value = true;
            const a = {
                value: initialValue,
                fleets: [...rf],
            };
            aprs.push(a);
        }
        */
        // **
        setFleets(lff);
        setOutputs(aprs);
        return aprs;
    };

    const canAddEntry = (index) => {
        const fi = fleets.findIndex( f => f.value === false);
        if ( binary ) {
            return fi >=0 && outputs.length < 2;
        }
        let lc = true;
        if ( outputs.length > 1 ) {
            const ft = outputs[outputs.length-1].fleets.find( f => f.value) ;
            if ( !ft ) lc = false;
        }
        return !allFleetValue && fi >= 0 && lc && index === outputs.length - 1;
    };

    const addEntry = () => {
        const aff = [...fleets];
        const ff = aff.filter( f => f.value === false);
        const naff = [];
        ff.forEach( f => {
            naff.push({
                name: f.name,
                label: f.label,
                value: false,
            });
            f.value = false;
        })
        const na = {
            value: initialValue,
            fleets: naff,
        };
        /* if ( na.fleets.length == 1) { */
        if ( na.fleets.length > 0) {
            na.fleets[0].value = true;
            
            const af = aff.find(f => f.name === na.fleets[0].name);
            if ( af ) {
                af.value = true;
            }
        }
        setFleets(aff);
        const apr = [...outputs];
        // console.log('Add Entry binary=' + binary + ', length=' + apr.length);
        if ( binary && apr.length > 0 ) {
            na.value = ! apr[0].value;
            // console.log('Added entry [' + apr.length + '] = ' + na.value);
        }
        apr.push(na);
        setOutputs(apr);
        
    };

    const save = (outs) => {
        if ( typeof onChange === 'function' ) {
            onChange(outs);
        }
    };


    const onControlValueChanged = (outs, aindex, value) => {
        const ap = [...outs];
        const a = ap[aindex]
        a.value = value;
        // console.log('Control value changed [' + aindex + '] = ' + value);
        if ( binary ) {
            if ( aindex === 0 && ap.length > 1) {
                ap[1].value = !value;
            } else if ( aindex === 1) {
                ap[0].value = !value;
            }
        }
        setOutputs(ap);
        save(ap);
    };

    const onAllFleetValueChanged = (apprs, aindex, value) => {
        setAllFleetValue(value);
        const ap = [...apprs];
        const a = ap[aindex]
        if (a.fleets.length > 1 ) {
            if (value) {
                const gff = [...fleets];
                a.fleets.forEach((f, findex) => {
                    // const f = a.fleets[findex];
                    // console.log('SEL FLEET: ' + JSON.stringify(f));
                    if (value) {
                        f.value = value;

                        const gf = gff.find(g => g.name === f.name);
                        if (gf) {
                            gf.value = value;
                        }


                    }
                    // console.log('SEL FLEET: ' + JSON.stringify(f));
                });
                setFleets(gff);
                // console.log('NEW FLEETS: ' + JSON.stringify(gff));
                // console.log('NEW OUTPUTS: ' + JSON.stringify(ap));
                const rap = filterOutputs(ap, gff);
                save(rap);
            } else {
                const gff = [...fleets];
                a.fleets.forEach((f, findex) => {
                    // const f = a.fleets[findex];
                    // console.log('SEL FLEET: ' + JSON.stringify(f));
                    if (findex === 0 ) {
                        f.value = true;
                        const gf = gff.find(g => g.name === f.name);
                        if (gf) {
                            gf.value = true;
                        }
                    } else {
                        f.value = false;
                        const gf = gff.find(g => g.name === f.name);
                        if (gf) {
                            gf.value = false;
                        }
                    }
                    // console.log('SEL FLEET: ' + JSON.stringify(f));
                });
                setFleets(gff);
                // console.log('NEW FLEETS: ' + JSON.stringify(gff));
                // console.log('NEW OUTPUTS: ' + JSON.stringify(ap));
                const rap = filterOutputs(ap, gff);
                save(rap);
            }
        }
        
    };

    const onFleetValueChanged = (apprs, aindex, fleet, findex, value) => {
        const ap = [...apprs];
        const a = ap[aindex]
        const f = a.fleets[findex];
        // console.log('SEL FLEET: ' + JSON.stringify(f));
        const sf = a.fleets.filter ( i => i.value );
        // const bs = value || sf.length > 1 || aindex === outputs.length - 1;
        const bs = value || sf.length > 1 || (aindex === outputs.length - 1 && aindex > 0);
        /* if ( a.fleets.length > 1  && bs ) { */
        if ( bs ) {
            f.value = value;
            f.disabled = false;
            const gff = [...fleets];
            const gf = gff.find(f => f.name === fleet.name);
            if (gf) {
                gf.value = value;
            }
            setFleets(gff);
            // console.log('NEW FLEETS: ' + JSON.stringify(gff));
            // console.log('NEW OUTPUTS: ' + JSON.stringify(ap));
            const rap = filterOutputs(ap, gff);
            save(rap);
        }
    };

    const topSelection = (mgl = 2) => {
        if ( position === 'top' && Array.isArray(outputs) && outputs.length > 0 && outputs[0].fleets?.length === 1) {
            return (
                <Box sx={{display: 'flex', marginLeft: mgl, paddingBottom: 1, paddingTop: 1}} >
                    <FormControlLabel
                            control={<Checkbox checked sx={{
                            color: FLEET_COLOR_DARKER,
                            '&.Mui-checked': {
                                color: FLEET_COLOR,
                            },
                            '&.Mui-disabled': {
                                color: '#e0e0e0',
                            },
                            paddingTop: '0.5ex', paddingBottom: '0.5ex',
                            
                        }} size="small"
                            
                         />} label={outputs[0].fleets[0].label} />
                </Box>
            );
        }
        return (
            Array.isArray(outputs) && outputs.length > 0 && outputs[0].fleets?.length > 1 && 
             <Box sx={{display: 'flex', marginLeft: mgl, paddingBottom: 1, paddingTop: 1}} >
                <Typography sx={{marginRight: 2, fontStyle: 'italic'}}>Applies to all selected fleets: </Typography>
                <FormControlLabel control={<Switch checked={allFleetValue} sx={{
                    
                }} size="small"
                    disableTypography={false}
                    onChange={(event) => onAllFleetValueChanged(outputs, 0, event.target.checked)} />} 
                    label={allFleetValue ? <Typography sx={{fontWeight: 'bold'}}>Yes</Typography> : <Typography sx={{fontWeight: 'bold'}}>No</Typography>} />
            </Box>
        );
    };

    const fleetSelection = (apr, aindex, mxw = '600px', mgl = 3) => {
        if ( position === 'right' && Array.isArray(outputs) && outputs.length > 0 && outputs[0].fleets?.length === 1) {
            return (
                <Box sx={{display: 'flex', marginLeft: mgl, paddingBottom: 1, paddingTop: 1, width: '100%', justifyContent: 'flex-start'}} >
                    <FormControlLabel
                            control={<Checkbox checked sx={{
                            color: FLEET_COLOR_DARKER,
                            '&.Mui-checked': {
                                color: FLEET_COLOR,
                            },
                            '&.Mui-disabled': {
                                color: '#e0e0e0',
                            },
                            paddingTop: '0.5ex', paddingBottom: '0.5ex',
                            
                        }} size="small"
                            
                         />} label={outputs[0].fleets[0].label} />
                </Box>
            );
        }
        return (
            /* !allFleetValue && */
            <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: mxw, width: '100%'}}>
                <Box sx={{ marginLeft: mgl, paddingLeft: 2, display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', width: '100%',
                    backgroundColor: '#f6f6ff', paddingBottom: 0 }} >
                    {(aindex > 0 || apr.fleets.length > 1) && apr.fleets.map((fleet, findex) => {
                        // console.log('   Fleet ' + JSON.stringify(fleet));
                        return (<FormControlLabel disabled={allFleetValue} 
                            sx={{display: !fleet.value && aindex < outputs.length - 1 ? 'none' : 'inline-flex'}}
                            control={<Checkbox checked={fleet.value} sx={{
                            color: FLEET_COLOR_DARKER,
                            '&.Mui-checked': {
                                color: FLEET_COLOR,
                            },
                            '&.Mui-disabled': {
                                color: '#e0e0e0',
                            },
                            paddingTop: '0.5ex', paddingBottom: '0.5ex',
                            
                        }} size="small"
                            
                            onChange={(event) => onFleetValueChanged(outputs, aindex, fleet, findex, event.target.checked)} />} label={fleet.label} />);
                    }
                    )}
                </Box>
                <Box sx={{backgroundColor: '#f6f6ff'}}>
                    { canAddEntry(aindex) && 
                        <IconButton onClick={addEntry} size="small">
                            <AddBoxIcon />
                        </IconButton>
                    }
                </Box>
            </Box>
        );

    };

    // control({ value: apr.value, onControlChange: (newValue) => onControlValueChanged(outputs, aindex, newValue) })

    // testing
    return (
        <Box sx={{paddingBottom: 2}} >
            {Array.isArray(outputs) && outputs.length > 0 && <Typography>{Array.isArray(fields) && index < fields.length ? fields[index].label : ''}</Typography> }
            {topSelection()}
            {Array.isArray(outputs) && outputs.map((apr, aindex) =>
                <React.Fragment>
                    {position === 'top' && fleetSelection(apr, aindex, '900px', 4)}
                    <Box sx={{ paddingLeft: 2, paddingTop: 1, display: 'flex', alignItems: align}}>
                        {typeof control === 'function' && <Box sx={position === 'top' ? {paddingLeft: 2, width: '100%'} : {paddingLeft: 2, }}>{control({ value: apr.value, onControlChange: (newValue) => onControlValueChanged(outputs, aindex, newValue) }) }</Box>}
                        {position === 'right' && fleetSelection(apr, aindex)}
                    </Box>
                </React.Fragment>)
            }
            {/* canAddEntry() && 
            <Box sx={{paddingLeft: 1}} >
                <IconButton onClick={addEntry}>
                    <AddBoxIcon />
                </IconButton>
            </Box>
            */}
        </Box>
    );

}



function SupplementTitle (props) {
    const {
        fields, // All fields definitions
        values, // all values
        index,
        placeholder='Please input title',
        // item,       // current field item
        // itemIndex,  // current field item index
        onChange,
    } = props;

    const TitleControl  = function (props) {
        const {
            onControlChange,
            value,
            ...other
        } = props;
    
        return (
            <Box sx={{width: '100%', paddingBottom: 3}}>
                <TextField value={value} onChange={(event) => onControlChange(event.target.value)} size="small" fullWidth  sx={{width: '100%'}}  
                    placeholder={placeholder} />
            </Box>
        );
    }

    return (
        <SupplementControl fields={fields} values={values} index={index} onChange={onChange}
            control={TitleControl} initialValue={''} position="top" />
    );


}

function GeneralLimitedControl (props) {
    const {
        onControlChange,
        value,
        ...other
    } = props;
    // const [distValue, setDistValue] = useState('general');
    /*
    useEffect(() => {
        console.log('Limited control = ' + value);
    }, [value]);
    */

    const onValueChanged = (event) => {
        // setDistValue(event.target.value);
        if ( event.target.value === 'limited' ) {
            onControlChange(true);
        } else {
            onControlChange(false);
        }
        // console.log('Limited = ' + event.target.value);
    }

    return (
        <Box>
            <RadioGroup
                row={true}
                value={value ? 'limited' : 'general'}
                onChange={onValueChanged}
                size="small"
                sx={{ paddingLeft: 3, flexWrap: 'nowrap' }}
            >
                <FormControlLabel value="general" key="general-0" label="General"
                    control={<Radio />} />
                <FormControlLabel value="limited" key="limited-0" label="Limited"
                    control={<Radio />} />
            </RadioGroup>
        </Box>
    );
}

function SupplementLimitedDistribution (props) {
    const {
        fields, // All fields definitions
        values, // all values
        index, 
        // item,       // current field item
        // itemIndex,  // current field item index
        onChange,
    } = props;

    const handleChange = (param) => {
        if ( typeof onChange === 'function' ) {
            console.log('Limited value = ' + JSON.stringify(param));
            onChange(param);
        }
    };

    return (
        <SupplementControl fields={fields} values={values} index={index} onChange={handleChange}
            control={GeneralLimitedControl} initialValue={false} position="right" binary={true} />
    );


}


function SupplementEffectiveDate (props) {
    const {
        fields, // All fields definitions
        values, // all values
        index, 
        // item,       // current field item
        // itemIndex,  // current field item index
        onChange,
    } = props;

    const [serverTimezone, setServerTimezone] = useState('system');

    React.useEffect(() => {
            netGet('/config/timezone')
                .then( response => response.json())
                .then( tinfo => {
                    // console.log('Timezone: ' + JSON.stringify(tinfo));
                    setServerTimezone(tinfo.id);
                });
        },[]);

    const EffectiveDateControl = function (props) {
        const {
            onControlChange,
            value,
            ...other
        } = props;
    
        
    
        const onDateValueChanged = (newValue) => {
            if ( typeof onControlChange === 'function' ) {
                onControlChange(newValue);
            }
        }
    
        return (
            <DatePicker value={typeof value === 'undefined' ? dayjs() : value} size="small"
                    onChange={onDateValueChanged} timezone={serverTimezone} />
        );
    };
    

    return (
        <SupplementControl fields={fields} values={values} index={index} onChange={onChange}
            control={EffectiveDateControl} initialValue={dayjs()} position="right" align="center" />
    );


}

function SupplementApprover (props) {
    const {
        fields, // All fields definitions
        values, // all values
        index, 
        item,       // current field item
        itemIndex,  // current field item index
        onChange,
    } = props;

    const [fleets, setFleets] = useState([]); // { label, name }
    const [approvers, setApprovers] = useState([]); // { name, title, fleets[] }
    const [allFleetValue, setAllFleetValue] = useState(true);

    useEffect(() => {
        if ( Array.isArray(fields) && Array.isArray(values) ) {
            const ai = fields.findIndex( f => f.name?.toLowerCase().includes('audience'));
            const auds = [];
            if ( ai >= 0 ) {
                const fa = fields[ai];
                const fv = values[ai];
                fa.properties.selectionData.forEach( item => {
                    if ( item.value && fv?.fields) {
                        const iv = fv.fields[item.value];
                        if ( iv ) {
                            const ffi = {
                                label: item.label,
                                name: item.value,
                            };
                            auds.push(ffi);
                        }
                    }
                });
                // console.log('AUD selData = ' + JSON.stringify(fa.properties.selectionData));
                // console.log('AUD value = ' + JSON.stringify(fv));
            }
            // console.log('Audiences: ' + JSON.stringify(auds));

            const fi = fields.findIndex( f => f.name?.toLowerCase().includes('fleet'));
            if ( fi >= 0 ) {
                const fd = fields[fi];
                const fv = values[fi];
                // console.log('FLEETS: ' + JSON.stringify(fd));
                // console.log('F VALUE: ' + JSON.stringify(fv));
                /*
                if ( index < values.length ) {
                    console.log('ITEM VALUE = ' + JSON.stringify(values[index].comp));
                }
                */
                if ( Array.isArray(fd.properties?.selectionData)) {
                    const ff = [];
                    const ff2 = [];
                    const allf = fd.properties.selectionData.find( item => item.value && item.value.includes('ALL') && fv.fields && fv.fields[item.value]);
                    fd.properties.selectionData.forEach( item => {
                        if ( item.value && !item.value.includes('ALL') &&  fv.fields) {
                            const iv = fv.fields[item.value];
                            if ( iv || allf) {
                                const ffi = {
                                    label: 'FC-' + item.label,
                                    name: item.value,
                                    value: true,
                                };
                                ff.push(ffi);
                                const ffi2 = {
                                    label: 'CC-' + item.label,
                                    name: 'CC_' + item.value,
                                    value: true,
                                }
                                ff2.push(ffi2);
                            }
                        }
                    });
                    // fleets stores the template
                    /*
                    if ( ff.length == 1) {
                        ff[0].value = true;
                    }
                    */
                    const ffa = [];
                    if ( auds.length > 0 ) {
                        auds.forEach( a => {
                            if ( a.name.includes('CABIN') ) {
                                ff2.forEach( f => ffa.push(f));
                            } else {
                                ff.forEach( f => ffa.push(f) );
                            }
                        });
                    } else {
                        ff.forEach( f => ffa.push(f) );
                        setFleets(ffa);
                    }
                    if ( ffa.length == 1) {
                        ffa[0].value = true;
                    }
                    setFleets(ffa);
                    // console.log('FLEETS: ' + JSON.stringify(ffa));
                    let apprs = [];
                    if ( index < values.length && values[index].comp) {
                        apprs = values[index].comp[item.value];
                        if ( !Array.isArray(apprs) ) {
                            apprs = [...approvers];
                        }
                        if ( apprs.length > 1 ) {
                            setAllFleetValue(false);
                        } else if ( apprs.length === 1) {
                            if ( Array.isArray(apprs[0].fleets) ) {
                                let v = true;
                                for(let y=0; y<apprs[0].fleets.length; y++) {
                                    const f = apprs[0].fleets[y];
                                    if ( f && typeof f.value === 'boolean') {
                                        if ( !f.value ) {
                                            v = false;
                                            break;
                                        }
                                    }
                                }
                                setAllFleetValue(v);
                            }
                        }
                    }
                    
                    let ap = [];
                    if ( apprs.length == 0) {
                        // initialize
                        ap = [{
                            name: '',
                            title: '',
                            fleets: [...ffa],
                        }];
                        setApprovers(ap);
                        // onFleetValueChanged(ap, 0, ap[0].fleets, 0, true);
                    } else {
                        ap = [...apprs];
                        // console.log('OLD APPROVERS: ' + JSON.stringify(approvers));
                        ap.forEach( item => {
                            const ffi = [];
                            ffa.forEach( f => {
                                const ifv = item.fleets.find( i => i.name === f.name);
                                // console.log('FLEET: ' + JSON.stringify(ifv));
                                ffi.push({
                                    label: f.label,
                                    name: f.name,
                                    value: ifv ? ifv.value: true,
                                });
                            })
                            item.fleets = ffi;
                        });
                        setApprovers(ap);
                        
                    }
                    filterApprovers(ap, ffa);
                    
                    // console.log('APPROVERS: ' + JSON.stringify(ap));
                }
            }
        }

    },[fields, values]);

    const save = (apprs) => {
        if ( typeof onChange === 'function' ) {
            onChange(apprs);
        }
    };

    const onNameChanged = (apprs, aindex, value) => {
        const ap = [...apprs];
        const a = ap[aindex]
        a.name = value;
        setApprovers(ap);
        save(ap);
    };

    const onTitleChanged = (apprs, aindex, value) => {
        const ap = [...apprs];
        const a = ap[aindex]
        a.title = value;
        setApprovers(ap);
        save(ap);
    };

    const onAllFleetValueChanged = (apprs, aindex, value) => {
        setAllFleetValue(value);
        const ap = [...apprs];
        const a = ap[aindex]
        if (a.fleets.length > 1 ) {
            if (value) {
                const gff = [...fleets];
                a.fleets.forEach((f, findex) => {
                    // const f = a.fleets[findex];
                    // console.log('SEL FLEET: ' + JSON.stringify(f));
                    if (value) {
                        f.value = value;

                        const gf = gff.find(g => g.name === f.name);
                        if (gf) {
                            gf.value = value;
                        }


                    }
                    console.log('SEL FLEET: ' + JSON.stringify(f));
                });
                setFleets(gff);
                // console.log('NEW FLEETS: ' + JSON.stringify(gff));
                // console.log('NEW OUTPUTS: ' + JSON.stringify(ap));
                const rap = filterApprovers(ap, gff);
                save(rap);
            } else {
                const gff = [...fleets];
                a.fleets.forEach((f, findex) => {
                    // const f = a.fleets[findex];
                    // console.log('SEL FLEET: ' + JSON.stringify(f));
                    if (findex === 0 ) {
                        f.value = true;
                        const gf = gff.find(g => g.name === f.name);
                        if (gf) {
                            gf.value = true;
                        }
                    } else {
                        f.value = false;
                        const gf = gff.find(g => g.name === f.name);
                        if (gf) {
                            gf.value = false;
                        }
                    }
                    // console.log('SEL FLEET: ' + JSON.stringify(f));
                });
                setFleets(gff);
                // console.log('NEW FLEETS: ' + JSON.stringify(gff));
                // console.log('NEW OUTPUTS: ' + JSON.stringify(ap));
                const rap = filterApprovers(ap, gff);
                save(rap);
            }
        }
        
    };

    const onFleetValueChanged = (apprs, aindex, fleet, findex, value) => {
        const ap = [...apprs];
        const a = ap[aindex]
        const f = a.fleets[findex];
        // console.log('SEL FLEET: ' + JSON.stringify(f));
        // console.log('SEL FLEET: ' + JSON.stringify(f));
        const sf = a.fleets.filter ( i => i.value );
        // const bs = value || sf.length > 1 || aindex === outputs.length - 1;
        const bs = value || sf.length > 1 || (aindex === approvers.length - 1 && aindex > 0);
        /* if ( a.fleets.length > 1 ) { */
        if ( bs ) {
            f.value = value;
            const gff = [...fleets];
            const gf = gff.find(f => f.name === fleet.name);
            if (gf) {
                gf.value = value;
            }
            setFleets(gff);
            // console.log('NEW FLEETS: ' + JSON.stringify(gff));
            // console.log('NEW APPRS: ' + JSON.stringify(ap));
            const rap = filterApprovers(ap, gff);
            save(rap);
        }
    };

    const filterApprovers = (apprs, fleets) => {
        // filter the approvers, the first approver sees all fleets,
        // each subsequent approver sees the leftover from previous
        // if there are not leftovers, remove the approver
        const aprs = [];
        let lff = [...fleets]; // initialize with all fleets
        apprs.forEach ( a => {
            const aff = [];
            lff.forEach(f => {
                aff.push({
                    name: f.name,
                    label: f.label,
                    value: false,
                })
            });
            // copy current value
            if (aff.length > 0) {
                aff.forEach(f => {
                    const af = a.fleets.find(cf => cf.name === f.name);
                    if (af) {
                        f.value = af.value;
                    } else {
                        f.value = false;
                    }
                });

                a.fleets = aff;
                /*
                if ( a.fleets.length == 1) {
                    a.fleets[0].value = true;
                    const af = fleets.find( f => f.name === a.fleets[0].name);
                    if ( af ) {
                        af.value = true;
                    }
                }
                */
                const fs = a.fleets.filter( v => v.value);
                if (fs?.length > 0) {
                    aprs.push(a);
                }
            }
            const nlff = aff.filter( f => f.value === false);
            lff = nlff;
        });
        lff = [...fleets];
        lff.forEach(f => f.value = false);
        apprs.forEach( a => {
            a.fleets.forEach(f => {
                if ( f.value ) {
                    const lf = lff.find( l => l.name === f.name);
                    if ( lf ) {
                        lf.value = f.value;
                    }
                }
            });
        });
        setFleets(lff);
        setApprovers(aprs);
        return aprs;

    };

    const canAddApprover = (index) => {
        const fi = fleets.findIndex( f => f.value === false);
        let lc = true;
        if ( approvers.length > 1 ) {
            const ft = approvers[approvers.length-1].fleets.find( f => f.value) ;
            if ( !ft ) lc = false;
        }
        return !allFleetValue && fi >= 0 && lc && index === approvers.length - 1;
    };

    const addApprover = () => {
        const aff = [...fleets];
        const ff = aff.filter( f => f.value === false);
        const naff = [];
        ff.forEach( f => {
            naff.push({
                name: f.name,
                label: f.label,
                value: false,
            });
            f.value = false;
        })
        const na = {
            name: '',
            title: '',
            fleets: naff,
        };
        if ( na.fleets.length > 0) {
            na.fleets[0].value = true;
            const af = aff.find(f => f.name === na.fleets[0].name);
            if ( af ) {
                af.value = true;
            }
            
        }
        setFleets(aff);
        const apr = [...approvers];
        apr.push(na);
        setApprovers(apr);

    };

    const topSelection = (mgl = 2) => {
        if ( Array.isArray(approvers) && approvers.length > 0 && approvers[0].fleets?.length === 1) {
            return (
                <Box sx={{display: 'flex', marginLeft: mgl, paddingBottom: 1, paddingTop: 1}} >
                    <FormControlLabel
                            control={<Checkbox checked sx={{
                            color: FLEET_COLOR_DARKER,
                            '&.Mui-checked': {
                                color: FLEET_COLOR,
                            },
                            '&.Mui-disabled': {
                                color: '#e0e0e0',
                            },
                            paddingTop: '0.5ex', paddingBottom: '0.5ex',
                            
                        }} size="small"
                            
                         />} label={approvers[0].fleets[0].label} />
                </Box>
            );
        }
        return (
            Array.isArray(approvers) && approvers.length > 0 && approvers[0].fleets?.length > 1 && 
             <Box sx={{display: 'flex', marginLeft: mgl, paddingBottom: 1, paddingTop: 1}} >
                <Typography sx={{marginRight: 2, fontStyle: 'italic'}}>Applies to all selected fleets: </Typography>
                <FormControlLabel control={<Switch checked={allFleetValue} sx={{
                    
                }} size="small"
                    disableTypography={false}
                    onChange={(event) => onAllFleetValueChanged(approvers, 0, event.target.checked)} />} 
                    label={allFleetValue ? <Typography sx={{fontWeight: 'bold'}}>Yes</Typography> : <Typography sx={{fontWeight: 'bold'}}>No</Typography>} />
            </Box>
        );
    };

    const fleetSelection = (apr, aindex, mxw = '900px', mgl = 3) => {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: mxw, width: '100%' }}>
                <Box sx={{
                    marginLeft: mgl, paddingLeft: 2, display: 'flex', alignItems: 'center',
                    flexWrap: 'wrap', width: '100%', backgroundColor: '#f6f6ff', paddingBottom: 0
                }} >
                    {/* apr.fleets.length > 1 && aindex == 0 &&
                    <FormControlLabel control={<Checkbox checked={allFleetValue} sx={{
                        color: FLEET_COLOR_DARKER,
                        '&.Mui-checked': {
                            color: FLEET_COLOR,
                        },
                        paddingTop: '0.5ex', paddingBottom: '0.5ex',
                    }} size="small"
                        onChange={(event) => onAllFleetValueChanged(approvers, aindex, event.target.checked)} />} label="ALL" />

                */}
                    {(aindex > 0 || apr.fleets.length > 1) && apr.fleets.map((fleet, findex) => {
                        // console.log('   Fleet ' + JSON.stringify(fleet));
                        return (<FormControlLabel 
                            sx={{display: !fleet.value && aindex < approvers.length - 1 ? 'none' : 'inline-flex'}}
                            disabled={allFleetValue} control={<Checkbox checked={fleet.value} sx={{
                            color: FLEET_COLOR_DARKER,
                            '&.Mui-checked': {
                                color: FLEET_COLOR,
                            },
                            '&.Mui-disabled': {
                                color: '#e0e0e0',
                            },
                            paddingTop: '0.5ex', paddingBottom: '0.5ex',
                        }} size="small"
                            onChange={(event) => onFleetValueChanged(approvers, aindex, fleet, findex, event.target.checked)} />} label={fleet.label} />);
                    }
                    )}
                </Box>
                {canAddApprover(aindex) &&
                    <Box sx={{ paddingLeft: 1, backgroundColor: '#f6f6ff' }} >
                        <IconButton onClick={addApprover} size="small">
                            <AddBoxIcon />
                        </IconButton>
                    </Box>
                }
            </Box>
        );

    };

    // testing
    return (
        <Box sx={{paddingBottom: 2}}>
            <Typography>{item?.label}</Typography>
            {topSelection()}
            { Array.isArray(approvers) && approvers.map( (apr, aindex) => 

                <React.Fragment>
                    {fleetSelection(apr, aindex, '900px')}
                    <Box sx={{ paddingLeft: 3, paddingTop: 1, paddingBottom: 2, display: 'grid', gridTemplateColumns: '48% 1fr 48%' }}>
                        <TextField label="Name" size="small" value={apr.name} onChange={event => onNameChanged(approvers, aindex, event.target.value)} />
                        <Typography sx={{ paddingLeft: 1, paddingRight: 1, justifySelf: 'center', alignSelf: 'center' }} >/</Typography>
                        <TextField label="Title" size="small" value={apr.title} onChange={event => onTitleChanged(approvers, aindex, event.target.value)} />
                        
                        {/*
                <Box sx={{ marginLeft: 3, paddingLeft: 2, display: 'flex', flexWrap: 'wrap', maxWidth: '350px', backgroundColor: '#f6f6ff' }} >
                    {(aindex > 0 || apr.fleets.length > 1) && apr.fleets.map((fleet, findex) =>
                        <FormControlLabel control={<Checkbox checked={fleet.value} sx={{
                            color: FLEET_COLOR_DARKER,
                            '&.Mui-checked': {
                              color: FLEET_COLOR,
                            },
                          }}
                            onChange={(event) => onFleetValueChanged(approvers, aindex, fleet, findex, event.target.checked) } />} label={fleet.label} />
                    )}
                </Box>
                */}
                    </Box>
                </React.Fragment>)
            
            }
            
        </Box>
    );
}

function SupplementFleets (props) {
    const {
        fields, // All fields definitions
        values, // all values
        index, 
        onChange,
    } = props;

    const [fleets, setFleets] = useState({});

    useEffect(() => {
        if (  Array.isArray(values) && index >=0 && index < values.length ) {
            const ff = {};
            if ( values[index].fields ) {
                const fs = values[index].fields;
                FLEETS.forEach( f => {
                    if ( typeof fs[f] === 'boolean') {
                        ff[f] = fs[f];
                    } else {
                        ff[f] = false;
                    }
                });
            }
            setFleets(ff);
        }

    },[values,index]);

    const FLEETS = [
        'ALL',
        '777',
        '787',
        'A320',
        'ATR',
        'Q300',
    ];

    const fleetValue = (fleet, findex) => {
        if ( fleets && typeof fleets[fleet] === 'boolean') {
            return fleets[fleet];
        }
        return false;
    };

    const onFleetValueChanged = (fleet, findex, val) => {
        const ff = fleets ? {...fleets} : {};
        ff[fleet] = val;
        if ( val && fleet === 'ALL') {
            for(let i=1; i<FLEETS.length; i++) {
                ff[FLEETS[i]] = val;
            }
        }
        setFleets(ff);
        if ( typeof onChange === 'function' ) {
            // const v= {comp: {...ff}, fields: {...ff}};
            onChange(ff);
        }
    };

    return (
        <Box sx={{paddingBottom: 2, paddingTop: 2}}>
            <Typography>{Array.isArray(fields) && index < fields.length ? fields[index].label + ' *' : ''}</Typography>
            <Box>
                <Box sx={{ display: 'flex', marginLeft: 1, paddingBottom: 1, paddingTop: 2 }} >
                    <Typography sx={{ marginRight: 2, fontStyle: 'italic' }}>Applies to all fleets: </Typography>
                    <FormControlLabel control={<Switch checked={fleetValue(FLEETS[0], 0)} sx={{

                    }} size="small"
                        disableTypography={false}
                        onChange={(event) => onFleetValueChanged(FLEETS[0], 0, event.target.checked)} />}
                        label={fleetValue(FLEETS[0], 0) ? <Typography sx={{ fontWeight: 'bold' }}>Yes</Typography> : <Typography sx={{ fontWeight: 'bold' }}>No</Typography>} />
                </Box>
            </Box>
            <FormGroup row sx={{ paddingLeft: 2 }}>
                {FLEETS.map((fleet,findex) => {
                    // console.log('   Fleet ' + JSON.stringify(fleet));

                    return (findex > 0 && <FormControlLabel control={<Checkbox 
                        disabled={findex > 0 && fleets[FLEETS[0]]}
                        checked={fleetValue(fleet, findex)} sx={{
                        '&.Mui-disabled': {
                            color: '#e0e0e0',
                        },
                    }}

                    onChange={(event) => onFleetValueChanged(fleet, findex, event.target.checked)} />} label={findex === 0 ? 'All fleets' : fleet} />);
                }
                )}
            </FormGroup>
        </Box>
    );
}


function SupplementApplicableManual (props) {
    const {
        fields, // All fields definitions
        values, // all values
        index, 
        onChange,
    } = props;

    const [audiences, setAudiences] = useState([]); // { label, name }
    const [manuals, setManuals] = useState([]); // { name, title, fleets[] }
    // const [allFleetValue, setAllFleetValue] = useState(true);

    const CABIN_MANUALS = [
        'CCGOP',
        'CSM',
        'ePAM',
        'iPad EFB',
        'Portable EFB',
        'Other',
    ];

    const FLIGHT_MANUALS = [
        
        'DDG',
        'iPad EFB',
        'FCOM',
        'FCTM',
        'FlySmart',
        'QRH',
        'Portable EFB',
        'Route Guide',
        'SOP',
        'Other',
    ];

    useEffect(() => {
        if ( Array.isArray(fields) && Array.isArray(values) ) {
            const ai = fields.findIndex( f => f.name?.toLowerCase().includes('audience'));
            const auds = [];
            if ( ai >= 0 ) {
                const fa = fields[ai];
                const fv = values[ai];
                fa.properties.selectionData.forEach( item => {
                    if ( item.value && fv?.fields) {
                        const iv = fv.fields[item.value];
                        if ( iv ) {
                            const ffi = {
                                label: item.label,
                                name: item.value,
                            };
                            auds.push(ffi);
                        }
                    }
                });
                // console.log('AUD selData = ' + JSON.stringify(fa.properties.selectionData));
                // console.log('AUD value = ' + JSON.stringify(fv));
            }
            console.log('Audiences: ' + JSON.stringify(auds));
            setAudiences(auds);
            let mans = [];
            auds.forEach( aud => {
                const man = {};
                if ( aud.name.includes('CABIN') ) {
                    man.ref = 'CC';
                    man.otherValue = '';
                    CABIN_MANUALS.forEach( item => {
                        man[item] = false;
                    })
                }
                if ( aud.name.includes('FLIGHT') ) {
                    man.ref = 'FC';
                    man.otherValue = '';
                    FLIGHT_MANUALS.forEach( item => {
                        man[item] = false;
                    })
                }
                mans.push(man);
            });
            const mv = values[index];
            if ( mv?.comp && Array.isArray(mv.comp) && mv.comp.length > 0 ) {
                mans = mv.comp;
            }
            setManuals(mans);
            console.log('Manuals: ' + JSON.stringify(mans));
        }

    },[fields, values]);

    const save = (mans) => {
        if ( typeof onChange === 'function' ) {
            onChange(mans);
        }
    };

    const manualValue = (index, name) => {
        if ( Array.isArray(manuals) &&  index < manuals.length ) {
            if ( typeof manuals[index][name] === 'boolean' ) {
                return manuals[index][name];
            }
        }
        return false;
    };

    const onManualValueChanged = (index, name, val) => {
        // console.log('setting man value = ' + index + ',' + name + ',' + val);
        if ( Array.isArray(manuals) &&  index < manuals.length ) {
            const mans = [...manuals];
            mans[index][name] = val;
            setManuals(mans);
            save(mans);
        }
    };

    const onOtherManualValueChanged = (index, val) => {
        if ( Array.isArray(manuals) &&  index < manuals.length ) {
            const mans = [...manuals];
            mans[index].otherValue = val;
            setManuals(mans);
            save(mans);
        }
    };
    

    const manualSelection = (manList, index) => {
        return (
            <Box sx={{ paddingLeft: 0, display: 'block' }} >
              {audiences.length > 1 &&
                <Typography sx={{paddingLeft: 1, fontStyle: 'italic', fontSize: '105%', textDecoration: 'underline'}} >{audiences[index].label}</Typography>
              }
                <FormGroup row={false} sx={{paddingLeft: 3}}>
                {Array.isArray(manList) && manList.map((man, mindex) => {
                    // console.log('   Fleet ' + JSON.stringify(fleet));
                    return (<FormControlLabel control={<Checkbox checked={manualValue(index, man)} sx={{
                        color: FLEET_COLOR_DARKER,
                        '&.Mui-checked': {
                            color: FLEET_COLOR,
                        },
                    }}

                        onChange={(event) => onManualValueChanged(index, man, event.target.checked)} />} label={man} />);
                }
                )}
                { Array.isArray(manuals) &&  index < manuals.length  && manuals[index].Other &&
                    <TextField value={manuals[index].otherValue} sx={{marginLeft: 4}}
                        onChange={(event) => onOtherManualValueChanged(index, event.target.value)} size="small" />

                }
                </FormGroup>
            </Box>
        );

    };

    // testing
    return (
        <Box sx={{paddingBottom: 2, paddingTop: 2}}>
            <Typography>{Array.isArray(fields) && index < fields.length ? fields[index].label : ''}</Typography>
            <Box sx={{ display: 'flex'}}>
            { Array.isArray(audiences) && audiences.map( (aud, aindex) => 
                <Box sx={{ paddingLeft: 2, paddingTop: 1, width: '40%'}}>
                    { aud?.name && aud.name.includes('CABIN') &&
                        manualSelection(CABIN_MANUALS, aindex)
                    }
                    { aud?.name && aud.name.includes('FLIGHT') &&
                        manualSelection(FLIGHT_MANUALS, aindex)
                    }
                </Box> )
            }
            </Box>
        </Box>
    );
}

/**
 * Update form fields based on the selected supplement given by supp parameter.
 * It returns the new values to be set on the form.
 * 
 * @param {*} fields 
 * @param {*} values 
 * @param {*} field 
 * @param {*} index 
 * @param {*} supp 
 */
const updateSupplementForm = (fields, values, field, index, supp, docid) => {
    return new Promise((resolve,reject) => {
        
        netGet(`/api/doc/supp/info/${docid}/${supp?.id}`)
            .then(response => response.json())
            .then(info => {
                console.log('EX SUP INFO: ' + JSON.stringify(info));
                const fv = [...values];
                let fleetIndex = -1;
                let approversIndex = -1;
                let audienceIndex = -1;
                const selectedAudiences = [];
                const selectedFleets = [];
                const selectedModels = [];
                fields.forEach((f, i) => {
                    if (f.name) {
                        const fname = f.name.toLowerCase();
                        console.log('SCAN field: ' + fname);
                        if ( fname.includes('audience') ) {
                            const aud = info.properties?.audience;
                            if ( aud ) {
                                audienceIndex = i;
                                f.properties.selectionData.forEach( item => {
                                    if ( item.value.includes('CABIN') ) {
                                        const cv = aud['CABIN'];
                                        fv[i].fields[item.value] = cv;
                                        if ( cv ) {
                                            selectedAudiences.push('CC');
                                        }
                                    } else {
                                        const cv = aud['FLIGHT'];
                                        fv[i].fields[item.value] = cv;
                                        if ( cv ) {
                                            selectedAudiences.push('FC');
                                        }
                                    }
                                });
                            }
                        } else if ( fname.includes('fleet') ) {
                            const fleets = info.properties?.fleets;
                            fleetIndex = i;
                            if ( fleets ) {
                                f.properties.selectionData.forEach( item => {
                                    if ( item.value.includes('777') ) {
                                        const cv = fleets['777'];
                                        fv[i].fields[item.value] = cv;
                                        if ( cv ) {
                                            selectedFleets.push('777');
                                        }
                                    } else if ( item.value.includes('787') ) {
                                        const cv = fleets['787'];
                                        fv[i].fields[item.value] = cv;
                                        if ( cv ) {
                                            selectedFleets.push('787');
                                        }
                                    } else if ( item.value.includes('300') ) {
                                        const cv = fleets['Q300'];
                                        fv[i].fields[item.value] = cv;
                                        if ( cv ) {
                                            selectedFleets.push('Q300');
                                        }
                                    } else if ( item.value.includes('ATR') ) {
                                        const cv = fleets['ATR'];
                                        fv[i].fields[item.value] = cv;
                                        if ( cv ) {
                                            selectedFleets.push('ATR');
                                        }
                                    } else if ( item.value.includes('320') ) {
                                        const cv = fleets['A320'];
                                        fv[i].fields[item.value] = cv;
                                        if ( cv ) {
                                            selectedFleets.push('A320');
                                        }
                                    }
                                });
                                console.log('Loaded fleets: ' + JSON.stringify(selectedFleets));
                                if ( selectedAudiences.includes('CC') ) {
                                    selectedFleets.forEach(f => {
                                        selectedModels.push('CC-' + f);
                                    });
                                }
                                if ( selectedAudiences.includes('FC') ) {
                                    selectedFleets.forEach(f => {
                                        selectedModels.push(f);
                                    });
                                }
                                // console.log('Loaded Models: ' + JSON.stringify(selectedModels));
                            } 
                        } else if ( fname.includes('title') ) {
                            const tt = info.properties?.title;
                            if ( Array.isArray(tt) ) {
                                tt.forEach( t => {
                                    if ( Array.isArray(t.fleets) ) {
                                        t.fleets.forEach( f => {
                                            if ( f.name ) {
                                                f.name = f.name.replace('-','_');
                                            }
                                        })
                                    }
                                });
                                fv[i].comp = tt;
                                
                            }
                            // console.log('Loaded title: ' + JSON.stringify(tt));
                        } else if (fname.includes('applicable')) {
                            const ap = info.properties?.applicableTo;
                            if (Array.isArray(ap)) {
                                fv[i].comp = ap;
                                
                            }

                        } else if (fname.includes('restrict') || fname.includes('distribu')) {
                            /*
                            const rv = info.properties?.restricted;
                            if ( typeof rv === 'boolean' ) {
                                fv[i] = rv;
                            }
                            */
                            const dist = info.properties?.distribution;
                            // console.log('Loaded distrib = ' + JSON.stringify(dist));
                            const dcomp = [];
                            if (dist?.GENERAL) {
                                const item = {
                                    value: false,
                                    fleets: [],
                                };
                                selectedModels.forEach(f => {
                                    const ff = {
                                        name: f.replace('-','_'),
                                        label: f.includes('CC') ? f : 'FC-' + f,
                                        value: dist.GENERAL.includes(f),
                                    }
                                    item.fleets.push(ff);
                                });
                                dcomp.push(item);
                            }
                            if (dist?.LIMITED) {
                                const item = {
                                    value: true,
                                    fleets: [],
                                };
                                selectedModels.forEach(f => {
                                    const ff = {
                                        name: f.replace('-','_'),
                                        label: f.includes('CC') ? f : 'FC-' + f,
                                        value: dist.LIMITED.includes(f),
                                    }
                                    item.fleets.push(ff);
                                });
                                dcomp.push(item);
                            }
                            fv[i].comp = dcomp;
                            // console.log('Resolved distrib = ' + JSON.stringify(dcomp));
                        } else if ( fname.includes('supersed') || fname.includes('cancel')) {
                            let cv = info.properties?.cancelling;
                            if ( typeof cv === 'string' && cv.length > 0 ) {
                                cv = cv.trim();
                                if ( cv.length > 0 ) {
                                    fv[i] = cv;
                                } else {
                                    fv[i] = 'N/A';
                                }
                            } else {
                                fv[i] = 'N/A';
                            }
                        } else if (fname.includes('number')) {
                            const num = info.properties?.number;
                            // console.log('NUMBER field value: ' + JSON.stringify(fv[i]));
                            
                            if (typeof num === 'string' && num.length > 0 ) {
                                f.properties.selectionData.forEach(item => {
                                    if (item.other) {
                                        fv[i].value = item.value;
                                        fv[i].other[item.value] = num;
                                    }
                                });
                            }
                        } else if ( fname.includes('approve') ) {
                            approversIndex = i;
                        } else if ( fname.includes('date') ) {
                            const ed = info.properties?.effectiveDate;
                            // console.log('Loaded effDate: ' + JSON.stringify(ed));
                            if ( Array.isArray(ed) ) {
                                ed.forEach( item => {
                                    if ( typeof item.value === 'string' && item.value.length > 0 ) {
                                        item.value = dayjs(item.value);
                                    }
                                    if ( Array.isArray(item.fleets) ) {
                                        item.fleets.forEach( f => {
                                            if ( f.name ) {
                                                f.name = f.name.replace('-','_');
                                            }
                                        });
                                    }
                                });
                                fv[i].comp = ed;

                            }
                        }

                    } 
                });
                if ( fleetIndex >= 0 && approversIndex >= 0 && audienceIndex >= 0) {
                    const signs = info.properties?.signatures;
                    const ff = fields[fleetIndex];
                    const lv = fv[fleetIndex];
                    const df = fields[audienceIndex];
                    const dv = fv[audienceIndex];
                    const af = fields[approversIndex];
                    const av = fv[approversIndex];
                    // console.log('Loaded signs: ' + JSON.stringify(signs));
                    if (signs) {
                        const ff1 = [];
                        const ff2 = [];
                        const allf = ff.properties.selectionData.find(item => item.value && item.value.includes('ALL') && lv.fields && lv.fields[item.value]);
                        ff.properties.selectionData.forEach(item => {
                            if (item.value && !item.value.includes('ALL') && lv.fields) {
                                const iv = lv.fields[item.value];
                                if (iv || allf) {
                                    const ffi = {
                                        label: item.label,
                                        name: item.value,
                                        value: false,
                                    };
                                    ff1.push(ffi);
                                    const ffi2 = {
                                        label: 'CC-' + item.label,
                                        name: 'CC_' + item.value,
                                        value: false,
                                    }
                                    ff2.push(ffi2);
                                }
                            }
                        });
                        const auds = [];
                        df.properties.selectionData.forEach(item => {
                            if (item.value && dv.fields) {
                                const iv = dv.fields[item.value];
                                if (iv) {
                                    const ffi = {
                                        label: item.label,
                                        name: item.value,
                                    };
                                    auds.push(ffi);
                                }
                            }
                        });
                        
                        const ffa = [];
                        if (auds.length > 0) {
                            auds.forEach(a => {
                                if (a.name.includes('CABIN')) {
                                    ff2.forEach(f => ffa.push(f));
                                } else {
                                    ff1.forEach(f => ffa.push(f));
                                }
                            });
                        } else {
                            ff1.forEach(f => ffa.push(f));
                        }
                        const comp = {};
                        af.properties.selectionData.forEach( item => {
                            if ( item.value.includes('SME') ) {
                                const sign = signs['SME'];
                                buildSign(sign, ffa, comp, av, item);
                            } else if ( item.value.includes('CHECK') ) {
                                const sign = signs['checked'];
                                buildSign(sign, ffa, comp, av, item);
                            } else if ( item.value.includes('OPERATIO') || item.value.includes('REVIEW')) {
                                const sign = signs['operationalReview'];
                                buildSign(sign, ffa, comp, av, item);
                            } else if ( item.value.includes('APPROVE')) {
                                const sign = signs['approved'];
                                buildSign(sign, ffa, comp, av, item);
                            }

                        });
                        av.comp = comp;
                    }
                    // console.log('Resolved signs: ' + JSON.stringify(av));
                }
                resolve(fv);
            }).catch(error => {
                console.log('Error fetching supplement info: ' + error);
                reject(error);
            });
        
    });
};

const buildSign = (sign, ffa, comp, av, item) => {
    if ( sign ) {
        const ccl = [];
        sign.forEach( s => {
            const cc = {
                name: s.name,
                title: s.title,
                fleets: [],
            }
            if ( Array.isArray(s.fleets)) {
                const tfleets = [];
                s.fleets.forEach( f => {
                    const fa = ffa.find( fi => fi.label === f);
                    if ( fa ) {
                        // let key = fa.name;
                        tfleets.push( f);
                        cc.fleets.push({
                            label: !fa.label.startsWith('FC-') && !fa.label.startsWith('CC-') ? 'FC-' + fa.label : fa.label,
                            name: fa.name ? fa.name.replace('-','_') : fa.label,
                            value: true,
                        });
                    }
                });
                ffa.forEach( fi => {
                    if ( !tfleets.includes(fi.label) ) {
                        cc.fleets.push({
                            label: !fi.label.startsWith('FC-') && !fi.label.startsWith('CC-') ? 'FC-' + fi.label : fi.label,
                            name: fi.name ? fi.name.replace('-','_') : fi.label,
                            value: false,
                        });
                    }
                });
            }
            ccl.push(cc);
        });
        
        comp[item.value] = ccl;
        av.fields[item.value] = true;
    }
};

export {SupplementApprover, updateSupplementForm, SupplementLimitedDistribution, SupplementEffectiveDate, 
    SupplementTitle, SupplementApplicableManual, SupplementFleets, SupplementOutputs};