import React, { Component, useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch, useStore } from 'react-redux';
// import store from 'app/store';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import debounce from 'lodash.debounce';
import { setCurrentMenu } from 'features/menu/menuSlice';
import postal from 'postal';
import { UserInfo, netGet, netPost } from "../network";

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SchemaIcon from '@mui/icons-material/Schema';
import SearchIcon from '@mui/icons-material/Search';
import LaunchIcon from '@mui/icons-material/Launch';
import Tooltip from '@mui/material/Tooltip';
import RunCircleIcon from '@mui/icons-material/RunCircle';
import LockIcon from '@mui/icons-material/Lock';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ToggleButton from '@mui/material/ToggleButton';
import { Typography } from "@mui/material";

import { MessageHooks } from "../../App";
import { WorkflowInstance } from "./workflow";
import { setWorkList, setWorkInstance } from "features/menu/menuSlice";
import { setWorkNode } from "features/menu/menuSlice";
import { setExpandedKeys } from "features/menu/menuSlice";
import { setDraftStarted, setLoadingViewer } from "features/process/processSlice";

import useVisibleOnScreen from "components/visible";

var wreload = 0;

const COLOR_MY_LOCK = 'green';
const COLOR_OTHER_LOCK = '#cc0000';
const COLOR_CHILD_LOCK = '#e0d910'; // '#e6df19';

const MY_MENU_NAME = "workfront";

const INSTANCE_PATH = '/C/workflow/instance';
const AVAILABLE_PATH = '/C/workflow/available';
const COMPLETED_PATH = '/C/workflow/completed';

const workflowFrontList = <WorkflowFrontList/>;

var lastCurrentMenu = '';
const menuSubscription = postal.subscribe({
    topic: "app.menu.change",
    callback: (data, envelope) => {
        // console.log('WORKFLOW GOT menu change: ' + JSON.stringify(data) + ',' + JSON.stringify(envelope));
        // updateLastCurrentMenu(data.menu);
        lastCurrentMenu = data.menu;
    }
});

function WorkflowFrontMenu (props) {
    const {
        reload=0,
        ...other
    } = props;
    const [instanceUpdate, setInstanceUpdate] = useState(10000);
    const [workflowUpdate, setWorkflowUpdate ] = useState(0);
    const [workflowInstanceId, setWorkInstanceId] = useState(-1);
    const showWorkList = useSelector((state) => state.workfront.showList);
    const workListValue = useSelector((state) => state.workfront.listValue);
    const showWorkInstance = useSelector((state) => state.workfront.showInstance);
    const workInstanceValue = useSelector((state) => state.workfront.instanceValue);
    const workInstanceNode = useSelector((state) => state.workfront.instanceNode);


    const [pendingUpdates, setPendingUpdates] = useState(false);
    const [lastPageIndex, setLastPageIndex] = useState(0);
    const [lastPageName, setLastPageName] = useState('');
    // const [lastCurrentMenu, setLastCurrentMenu] = useState('');
    const [menuACL, setMenuACL] = useState({});
    const theme = useTheme();
    const matchesDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    const [isVisible, containerRef] = useVisibleOnScreen();
    // const currentMenu = useSelector((state) => state.menu.currentMenu);
    const pageIndex = useSelector((state) => state.menu.pageIndex);
    const pageName = useSelector((state) => state.menu.pageName);

    const dispatch = useDispatch();

    React.useEffect(() => {
        // console.log('WORKFLOW VISIBLE: ' + isVisible);
        if ( isVisible ) {
            // console.log('Posting CurrentMenu = workflow...');
            // store.dispatch(setCurrentMenu('workflow'));
            postal.publish({
                topic: "app.menu.change",
                data: {
                    menu: MY_MENU_NAME,
                }
            });
            if ( pendingUpdates ) {
                setPendingUpdates(false);
                loadUpdates();
            }
            const aclm = {};
            netPost('/api/auth/acl/eval/multi', {
                params: [
                  {
                    path: INSTANCE_PATH,
                    action: 'BROWSE',
                    subjects: UserInfo.info.roles,
                  },
                  {
                    path: INSTANCE_PATH,
                    action: 'READ',
                    subjects: UserInfo.info.roles,
                  },
                  {
                    path: INSTANCE_PATH,
                    action: 'WRITE',
                    subjects: UserInfo.info.roles,
                  },
                  /*
                  {
                    path: AVAILABLE_PATH,
                    action: 'BROWSE',
                    subjects: UserInfo.info.roles,
                  },
                  {
                    path: AVAILABLE_PATH,
                    action: 'READ',
                    subjects: UserInfo.info.roles,
                  },
                  {
                    path: AVAILABLE_PATH,
                    action: 'WRITE',
                    subjects: UserInfo.info.roles,
                  },
                  {
                    path: COMPLETED_PATH,
                    action: 'BROWSE',
                    subjects: UserInfo.info.roles,
                  },
                  {
                    path: COMPLETED_PATH,
                    action: 'READ',
                    subjects: UserInfo.info.roles,
                  },
                  {
                    path: COMPLETED_PATH,
                    action: 'WRITE',
                    subjects: UserInfo.info.roles,
                  },
                  */
                ]
              }).then(response => {
                if (!response.ok) {
                  throw new Error('Status: ' + response.status);
                }
                return response.json();
              })
                .then(data => {
                  // console.log(`ACL Result [${AVAILABLE_PATH}] = ` + JSON.stringify(data));
                  if ( Array.isArray(data) ) {
                    
                      aclm[INSTANCE_PATH] = {
                          hide: !data[0].result,
                          disabled: !data[1].result,
                          write: data[2].result,
                      };
                      /*
                      aclm[AVAILABLE_PATH] = {
                          hide: !data[3].result,
                          disabled: !data[4].result,
                          write: data[5].result,
                      };
                      aclm[COMPLETED_PATH] = {
                          hide: !data[6].result,
                          disabled: !data[7].result,
                          write: data[8].result,
                      };
                      */
                    setMenuACL(aclm);
                  }
                  
                }).catch(error => {
                  console.log('Error: ' + error);
                });
        }
    }, [isVisible]);



    useEffect(() => {
        if (pageIndex === 0 && lastPageIndex !== 0 && pendingUpdates) {
            setPendingUpdates(false);
            loadPendingUpdates();
        }
        if (pageIndex !== lastPageIndex) {
            setLastPageIndex(pageIndex);
            if (workInstanceNode) {
                if (pageIndex === 1 ) {
                    // change icon to workflow
                    dispatch(setWorkList({ showList: false, listValue: true }));
                    dispatch(setWorkInstance({ showInstance: true, instanceValue: false }));
                    dispatch(setLoadingViewer(false));
                } else {
                    dispatch(setWorkList({showList: true, listValue: false}));
                    dispatch(setWorkInstance({showInstance: false, instanceValue: true}));
                }
            }
        }
    }, [pageIndex]);

    useEffect(() => {
        // console.log('Page Name CHANGED: ' + pageName);
        if ( pageName != lastPageName ) {
            setLastPageName(pageName);
            if ( workInstanceNode ) {
                if ( pageName === 'xmleditor') {
                    // change icon to workflow
                    dispatch(setWorkList({ showList: false, listValue: true }));
                    dispatch(setWorkInstance({ showInstance: true, instanceValue: false }));
                } else {
                    dispatch(setWorkList({showList: true, listValue: false}));
                    dispatch(setWorkInstance({showInstance: false, instanceValue: true}));
                }
            }
        }
    },[pageName]);

    useEffect(() => {
        loadPendingUpdates();
    },[workflowInstanceId]);

    const loadUpdates = () => {
        console.log("WORKFRONT loading updates...");
    };

    const loadPendingUpdates = () => {
        if (lastCurrentMenu === MY_MENU_NAME) {
            if (pageIndex === 0) {
                if (workInstanceNode?.data?.instance?.id === workflowInstanceId) {
                    netGet('/api/workflow/inst/' + workflowInstanceId)
                        .then(response => {
                            if (!response.ok) {
                                throw new Error('Status: ' + response.status);
                            }
                            return response.json();
                        })
                        .then(winst => {
                            setInstanceUpdate(instanceUpdate => instanceUpdate + 1);
                            const i = instanceUpdate + 1;
                            const win = JSON.parse(JSON.stringify(workInstanceNode));
                            win.data.instance = winst;
                            dispatch(setWorkNode(win));
                            WorkflowFrontModule.pageChange(<WorkflowInstance instance={winst} update={i} />);
                            console.log('Updated workflow instance: ' + workflowInstanceId + ': ' + JSON.stringify(winst));
                            setWorkInstanceId(-1);
                        }).catch(error => console.log(error));
                }
            } else {
                setPendingUpdates(true);
            }
        }
        /*
        else if ( lastCurrentMenu === MY_MENU_NAME ) {
            setPendingUpdates(true);
        }
        */
    };

    const onListChange = () => {
        // console.log('LIST_CHANGE: pageIndex=' + pageIndex + ', workList=' + workListValue);
        if ( pageIndex === 1 ) {
            // use it to switch the page and reset to show list
            WorkflowFrontModule.pageIndexChange(0);
            dispatch(setWorkList({showList: true, listValue: false}));
            dispatch(setWorkInstance({showInstance: false, instanceValue: true}));
        } else if ( !workListValue ) {
            dispatch(setWorkList({showList: false, listValue: true}));
            dispatch(setWorkInstance({showInstance: true, instanceValue: false}));
            WorkflowFrontModule.pageChange(workflowFrontList);
        }
    };

    const onInstanceChange = () => {
        if ( showWorkInstance && !workInstanceValue && workInstanceNode ) {
            dispatch(setWorkList({showList: true, listValue: false}));
            dispatch(setWorkInstance({showInstance: false, instanceValue: true}));
            if ( workInstanceNode?.data?.instance ) {
                setInstanceUpdate( instanceUpdate => instanceUpdate + 1);
                const i = instanceUpdate + 1;
                WorkflowFrontModule.pageChange(<WorkflowInstance instance={workInstanceNode.data.instance} update={i} />);
            }
        }
    };

    const workflowInfo = (message) => {
        let wevent = message.data;
        console.log('Received workflow message: ' + wevent.action + ' : ' + wevent.type + ' : ' + wevent.id);
        if (wevent.type === 'WorkflowInstance') {
            /*
            setWorkflowUpdate(workflowUpdate => workflowUpdate + 1);
            wreload = wreload + 1;
            updateInstances();
            */
            // console.log('CURRENT MENU = ' + lastCurrentMenu );
            if ( wevent.action === 'MODIFIED') {
                setWorkInstanceId(wevent.id);
            }
            if (lastCurrentMenu === MY_MENU_NAME && pageIndex === 0) {
                // console.log('updading workflow...');
                // loadUpdates();
            } else if (lastCurrentMenu === MY_MENU_NAME ) {
                setPendingUpdates(true);
            }
        }
    };

    const handleMenuIconClick = (event) => {
        /*
        if ( menuIcon === 'back' ) {
            WorkflowModule.menuIconChange('app');
            setMenuIcon('app');
        } else {
            WorkflowModule.menuIconChange('back');
            setMenuIcon('back');
        }
        */
    };

    // 


    MessageHooks["workflow"]["WorkflowFrontMenu"] = workflowInfo;
    WorkflowFrontModule.onMenuIconClick = handleMenuIconClick;


    return (
        <React.Fragment>
            { showWorkList && <Box sx={{ marginTop: '23px', textAlign: 'center' }}>
                <Tooltip title="Workflow List">
                    <ToggleButton selected={workListValue} onChange={onListChange} size="small" sx={{ border: 0 }}>
                        <FormatListBulletedIcon/>
                    </ToggleButton>
                </Tooltip>
            </Box> }
            { showWorkInstance && 
             <Box sx={{ marginTop: '23px', textAlign: 'center' }}>
                    <Tooltip title="Current Workflow">
                        <ToggleButton selected={workInstanceValue} onChange={onInstanceChange} size="small" sx={{ border: 0, color: 'rgba(0,0,0,0.5)' }}>
                            <AccountTreeIcon/>
                        </ToggleButton>
                    </Tooltip>
            </Box> }
        </React.Fragment>
    );

};

const MAX_SCROLL_TIMER_COUNT = 8;
var scrollTimerCount = 0;

function WorkflowFrontList (props) {

    const [workNodes, setWorkNodes] = useState([]);
    const [docList, setDocList] = useState([]);
    const [tableHeight, setTableHeight] = useState(window.innerHeight - 190);
    const [instanceUpdate, setInstanceUpdate] = useState(0);
    const [selectedNodeKey, setSelectedNodeKey] = useState(null);
    const selectedNode = useSelector((state) => state.workfront.instanceNode);
    const expandedKeys = useSelector((state) => state.workfront.expandedKeys);
    const dispatch = useDispatch();
    
    const CELL_PADDING = '0.8ex';

    useEffect(() => {
        updateInstances();
        // console.log('tableHeight: ' + tableHeight);
        const el = document.querySelector('tr[aria-selected="true"]');
    },[]);

    useEffect(() => {
        let tid;
        if ( selectedNode?.key ) {
            setSelectedNodeKey(selectedNode.key);
            scrollTimerCount = 0;
            tid = setTimeout(scrollSelected, 500);
        }
        return () => tid && clearTimeout(tid);
    },[selectedNode])

    const scrollSelected = () => {
        const el = document.querySelector('tr[aria-selected="true"]');
        if (el) {
            el.scrollIntoView(false);
        } else if ( scrollTimerCount < MAX_SCROLL_TIMER_COUNT ) {
            scrollTimerCount = scrollTimerCount + 1;
            setTimeout(scrollSelected, 500);
        } else {
            console.log("Timeout expired: could not find selected tree node...");
        }
    };

    const updateInstances = () => {
        // console.log('Fetching workflow instances... ' + reload);
        netGet('/api/workflow/inst/find/tree')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Status: ' + response.status);
                }
                return response.json();
            })
            .then(data => {
                // console.log("WorkNodes: " + JSON.stringify(data));
                setWorkNodes(data);


            }).catch(error => {
                console.log('Error: ' + error);
            });
        /*
        netGet('/api/doc/roots?sort=alpha')
            .then(response => response.json())
            .then(docs => {
                if (Array.isArray(docs)) {
                    // console.log('Got ' + docs.length + ' documents: ' + JSON.stringify(docs) );
                    setDocList(docs);
                    netGet('/api/workflow/inst/find/tree')
                        .then(response => {
                            if (!response.ok) {
                                throw new Error('Status: ' + response.status);
                            }
                            return response.json();
                        })
                        .then(data => {
                            
                            

                        }).catch(error => {
                            console.log('Error: ' + error);
                        });

                }
            }).catch(error => {
                console.log('Error fetching document list: ' + error);
            });
        */
    };


    const drawtable = () => {
        /*
        return (
            <TreeTable value={workNodes} tableStyle={{ width: '100%' }} emptyMessage={<span></span>}
                    selectionMode="single" selectionKeys={selectedNodeKey} onSelectionChange={handleSelectedNode} metaKeySelection={false} scrollable scrollHeight="250px">
                    <Column field="name" header={itemHeaderLabel} alignHeader="left" style={{ padding: 0, paddingLeft: '1ex', textAlign: 'left', width: '70%' }}
                        headerStyle={{ padding: '0.9ex', width: '70%', fontSize: '90%' }} expander ></Column>
                    <Column field="def" header="Default Value" align="center" headerStyle={{ padding: '0.9ex', fontSize: '90%' }}></Column>
                    <Column field="other" header="Other Field" align="center" headerStyle={{ padding: '0.9ex', fontSize: '90%' }}></Column>
                </TreeTable>
        );
        */
    };

    const handleSelectedNode = (event) => {
        if ( event.value ) {
            setSelectedNodeKey(event.value);
        }
    };

    const handleToggleNode = (event) => {
        dispatch(setExpandedKeys(event.value));
    }

    const openInstance = (node) => {
        const winst = node?.data?.instance;
        if ( node?.data?.type === 'INST' && winst ) {
            setInstanceUpdate( instanceUpdate => instanceUpdate + 1);
            setSelectedNodeKey(node.key);
            const i = instanceUpdate + 1;
            WorkflowFrontModule.pageChange(<WorkflowInstance instance={winst} update={i} />);
            dispatch(setWorkNode(node));
            dispatch(setWorkList({showList: true, listValue: false}));
            dispatch(setWorkInstance({showInstance: false, instanceValue: true}));
        }
    };

    const toggleNode = (node) => {
        let xkeys = expandedKeys ? { ...expandedKeys} : {};
        if ( xkeys[node.key]) {
            delete xkeys[node.key];
        } else {
            xkeys[node.key] = true;
        }
        dispatch(setExpandedKeys(xkeys));
    };

    const actionTemplate = (node,options) => {
        return (
            node?.data?.type === 'INST' && 
            <Box sx={{padding: 0}}>
                    <Tooltip title="Open Workflow">
                        <IconButton aria-label="open" color="primary" sx={{ padding: 0 }} onClick={() => openInstance(node)} size="small">
                            <LaunchIcon />
                        </IconButton>
                    </Tooltip>
            </Box>
        );
    };

    const statusTemplate = (node,options) => {
        let label =  Array.isArray(node?.data?.users) && node.data.users.length > 0 ?
            'Assigned to ' + node.data.users[0] : '';
        if ( node?.data?.status === 'CLD' ) {
            label = 'Child items assigned';
        }
        const color = node?.data?.status === 'OWN' ? COLOR_MY_LOCK : ( node?.data?.status === 'CLD' ? COLOR_CHILD_LOCK : COLOR_OTHER_LOCK);
        return ( 
            (node?.data?.status === 'OWN' || node?.data?.status === 'LCK' || node?.data?.status === 'CLD') &&
            <Box sx={{padding: 0, margin: 0, paddingLeft: '3em', color: color, textAlign: 'left', width: '100%'}}> 
                <Tooltip title={label}>
                    <LockIcon fontSize="18px"/>
                </Tooltip>
            </Box>
        );
    };

    // <Typography sx={{display: 'inline'}}>{node?.data?.label}</Typography>

    const labelTemplate = (node,options) => {
        return ( node?.data?.type === 'INST' ?
            <Button variant="text" onClick={() => openInstance(node)} sx={{color: 'rgba(0,0,0,0.87)'}}>{node?.data?.label}</Button> :
            <Button variant="text" onClick={() => toggleNode(node)} sx={{color: 'rgba(0,0,0,0.87)'}}>{node?.data?.label}</Button>
        );
    };

    // stateKey={'tree-table-workfront-ucs-session'} stateStorage={'session'}
    /*
    <Box sx={{ padding: 1, textAlign: 'center' }}>
                <TextField
                    id="wfl-search"
                    type="search"
                    size="small"
                    slotProps={{
                        input: {
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }
                    }}
                    sx={{ minWidth: '12em' }}
                />
            </Box>
                    <Column field="other" header="Other" align="center" headerStyle={{ padding: '0.9ex', fontSize: '90%', textAlign: 'center' }} bodyStyle={{padding: CELL_PADDING}}></Column>
                    <Column body={actionTemplate} align="center"></Column>

            */

    return (
        <Box sx={{padding: 2, height: '90%'}}>
            
            <Box sx={{minHeight: '80%'}}>
                <TreeTable value={workNodes} tableStyle={{ width: '100%', height: '100%' }} emptyMessage={<span></span>}
                    
                    selectionKeys={selectedNodeKey} expandedKeys={expandedKeys} onToggle={handleToggleNode}
                    selectionMode="single" onSelectionChange={handleSelectedNode} metaKeySelection={false} scrollable scrollHeight={'' + tableHeight + 'px'}
                    defaultSortOrder={0} rowHover >
                    <Column body={labelTemplate} header="Documents & Workflows" alignHeader="left" style={{ padding: 0, paddingLeft: '1ex', textAlign: 'left', width: '60%' }}
                        headerStyle={{ padding: '0.9ex', width: '60%', fontSize: '90%' }} 
                        bodyStyle={{textWrap: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '60%', padding: CELL_PADDING,}}
                        expander ></Column>
                    <Column header="Current Status" align="left" headerStyle={{ padding: '0.9ex', fontSize: '90%', textAlign: 'left', }} bodyStyle={{padding: CELL_PADDING, textAlign: 'left'}} body={statusTemplate}></Column>
                </TreeTable>
            </Box>
        </Box>
    );

}

// <SchemaIcon fontSize="inherit" />

const WorkflowFrontModule = {
    // label
    name: 'workfront',
    label: 'Workflow–Tree',
    barLabel: 'Workflow',
    barShortLabel: 'UCS',
    // icon
    icon: <AccountTreeIcon fontSize="inherit" />,
    // menu
    drawerContent: <WorkflowFrontMenu reload={wreload}/>,
    // initial body page
    pageContent: workflowFrontList,
    // send new body page on menu actions
    pageChange: (page) => {},
    pageIndexChange: (index) => {},
    drawerChange: (drawer) => {},
    drawerOpen: (open) => {},
    menuIconChange: (icon) => {},
    onMenuIconClick: (event) => {},
    drawerWidth: 50,

    hooks: {}, // { workflow: { top: workflowInfo } },
    route: 
        {
            path: 'workfront',
            element: <WorkflowFrontMenu reload={wreload}/>,
        }
    ,
    aclPaths: [ 
        {path: '/C/workfront', label: 'Workflow-Tree'}, 
        /*
        {path: '/C/workfront/instance', label: 'Instances'}, 
        {path: '/C/workfront/available', label: 'Available'},
        {path: '/C/workfront/completed', label: 'Completed'}
        */
    ],
};

export { WorkflowFrontModule as default};